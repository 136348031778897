import { EquipmentModel } from '../../../../../../models/New/Equipment'
import EquipmentImageBox from '../../../../../../components/EquipmentImageBox'
import './NewEquipmentDetails.scss'
import EquipmentInfoCart from './EquipmentInfoCart'

interface NewEquipmentDetailsProps {
    isRootEquipment: boolean
    equipment: EquipmentModel
}

function NewEquipmentDetails(props: NewEquipmentDetailsProps) {
    const { equipment, isRootEquipment } = props

    return (
        <div className="customer-details-tab-container">
            {isRootEquipment && (
                <div className="equipment-info-cart">
                    <EquipmentInfoCart equipment={equipment} />
                </div>
            )}
            <div className="equipment-info">
                <div className="top">
                    {!!equipment.images.length && <EquipmentImageBox title="Image" images={equipment?.images.sort((image) => (image.isFeatured ? -1 : 1))} />}
                    <div className="top-right">
                        <div className="bottom">
                            {equipment.brand && (
                                <div className="line">
                                    <span className="title">Brand</span>
                                    <span className="value">{equipment.brand}</span>
                                </div>
                            )}
                            {equipment.description && (
                                <div className="line">
                                    <span className="title">Description</span>
                                    <span className="value">{equipment.description}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewEquipmentDetails
