import { useCallback, useContext, useEffect, useState } from 'react'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import './CheckoutPage.scss'
import OrderRequirements from './Components/OrderRequirements'
import ContactService from '../../../services/UiService/Contact'
import { CustomerContactModel, CustomerEquipmentsModel } from '../../../models/New/Customer'
import { toast } from 'react-toastify'
import AuthService from '../../../services/UiService/Auth'
import { useNavigate } from 'react-router'
import { ICartInformation } from '../../../store/CustomerCheckoutContext/models'
import CompanyService from '../../../services/UiService/Company'
import { SearchPartContext } from './../../../store/SearchPartContext'
import PageWrapper from '../../PageWrapper'
import { CustomerCheckoutContext } from '../../../store/CustomerCheckoutContext/CustomerCheckoutContext'
import Order from './Components/Order/Order'
import { Tabs } from '../../../models/Ui/Tab'

export enum CartActionTypes {
    RFQ,
    PURCHASE,
}

function CustomerCheckoutPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [contacts, setContacts] = useState<CustomerContactModel[]>()
    const [equipmentCount, setEquipmentCount] = useState<number>(0)
    const [equipmentList, setEquipmentList] = useState<CustomerEquipmentsModel[]>([])
    const checkoutContext = useContext(CustomerCheckoutContext)
    const searchPartContext = useContext(SearchPartContext)
    const auth = new AuthService().getAuthUser()
    const navigate = useNavigate()

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])

    const onSuccessCallback = useCallback(
        async (action: CartActionTypes, data?: any) => {
            switch (action) {
                case CartActionTypes.PURCHASE:
                    handleResetContext()
                    toast.success('Purchase request sent successfully!')
                    navigate('/orders?activeTab=' + Tabs.PurchaseRequests)
                    break
                case CartActionTypes.RFQ:
                    handleResetContext()
                    toast.success('RFQ Sent successfully!')
                    navigate('/orders?activeTab=' + Tabs.Quotes)

                    break
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate]
    )

    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    const getContactsCallback = useCallback(async () => {
        try {
            if (auth.id) {
                const response = await ContactService.getCompanyContacts(auth.id)
                setContacts(response)
            }
        } catch (e: any) {
            onErrorCallback(e)
        }
    }, [auth.id, onErrorCallback])

    const getCustomerCallback = useCallback(async () => {
        const companyService = new CompanyService()
        try {
            if (auth.id) {
                const response = await companyService.getEquipmentCount(auth.id)
                setEquipmentCount(response)
                if (response === 1) {
                    const response = await companyService.getEquipmentList(auth.id)
                    setEquipmentList(response)
                }
            }
        } catch (e: any) {
            toast.error(e)
        }
    }, [auth.id])

    useEffect(() => {
        async function loadComponent() {
            onLoadingCallback()
            await getContactsCallback()
            await getCustomerCallback()
            onCompletedCallback()
        }

        loadComponent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getContactsCallback, getCustomerCallback, onCompletedCallback, onLoadingCallback])

    const handleResetContext = () => {
        checkoutContext.handleCartInformation({
            cartShow: false,
        } as ICartInformation)
        searchPartContext.reset()
        checkoutContext.reset()
    }

    return (
        <PageWrapper className="customer-checkout-page">
            {loading && <Loading />}
            <div className="customer-checkout-page-container">
                <div className="left-box">
                    <ContentBox title="Checkout">
                        <Order
                            manufacturers={undefined}
                            customer={undefined}
                            customerEquipmentCount={equipmentCount}
                            customerEquipmentList={equipmentList}
                            customerId={auth.id}
                            onCompleted={onCompletedCallback}
                            onLoading={onLoadingCallback}
                        />
                    </ContentBox>
                </div>
                <div className="right-box">
                    <OrderRequirements contacts={contacts} onLoading={onLoadingCallback} onCompleted={onCompletedCallback} onSuccess={onSuccessCallback as any} onError={onErrorCallback} />
                </div>
            </div>
        </PageWrapper>
    )
}

export default CustomerCheckoutPage
