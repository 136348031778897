import Panzoom, { PanzoomObject } from '@panzoom/panzoom'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { getFileTypeFromUrl, UploadFileType } from '../../utils/helpers'
import PdfViewer from '../PdfViewer'
import './style.scss'
import { Area } from '../ImageMapping/ImageMapping.interface'
import Button from '../Button'
import { EquipmentDrawingInputModel, EquipmentDrawingModel, EquipmentModel, PartsModel } from '../../models/New/Equipment'
import EquipmentService from '../../services/UiService/Equipment'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import PopoverItem from '../PopoverItem'
import { EquipmentActionTypes } from '../../models/Ui/Common'
import { CheckoutContext } from '../../store/NewCheckoutContext/CheckoutContext'
import { TechnicianViewType } from '../../store/models'
import SaveConfirmationModal from './ConfirmationModal'
import Icons, { IconType } from '../../Icon'
import MappingCircleSettings from '../ImageMapping/components/MappingCircleSettings'
import ZoomButton from '../ZoomButton'
import ImageMapping from '../ImageMapping'
import { EMappingCircleColor, EMappingCircleSize } from '../ImageMapping/components/MappingCircle/MappingCircle.interface'
import { toast } from 'react-toastify'

interface Props {
    classname?: string
    equipment: EquipmentModel
    customEquipment?: boolean
    customerSide?: boolean
    addToCart?: (data: PartsModel) => void
    onSuccess: (action: EquipmentActionTypes, data?: any) => void
    onLoading: () => void
    onCompleted: () => void
    selectedImageIndex: number
    onClickPreviousImage: () => void
    onClickNextImage: () => void
}

const DrawingDashboard: FC<Props> = ({
    onCompleted,
    onClickNextImage,
    onClickPreviousImage,
    classname,
    customerSide,
    equipment,
    customEquipment,
    addToCart,
    onSuccess,
    onLoading,
    selectedImageIndex,
}) => {
    const checkoutContext = useContext(CheckoutContext)
    const { equipmentId, assemblyId } = useParams()
    const [areas, setAreas] = useState<Area[]>([])
    const [mappingMode, setMappingMode] = useState<boolean>(false)
    const [allPartList, setAllPartList] = useState<PartsModel[]>([])
    const [imageLoading, setImageLoading] = useState<boolean>(false)
    const [saveConfirmationVisible, setSaveConfirmationVisible] = useState<boolean>(false)
    const [mappingCircleSettingsVisible, setMappingCircleSettingsVisible] = useState<boolean>(false)
    const [mappingCircle, setMappingCircle] = useState(JSON.parse(localStorage.getItem('mappingCircle')!))
    const [drawings, setDrawings] = useState<EquipmentDrawingModel[]>(equipment.drawings)
    const [scaleRatio, setScaleRatio] = useState<number>(1)
    const imageContainer = useRef<HTMLDivElement | null>(null)
    const panzoom = useRef<PanzoomObject | null>(null)
    const isQuickCart = checkoutContext.cartInformation.technicianViewType === TechnicianViewType.Quick || checkoutContext.cartInformation.technicianViewType === TechnicianViewType.PurchaseAs

    useEffect(() => {
        if (!mappingMode) {
            if (imageContainer.current) panzoom.current = Panzoom(imageContainer.current, { startScale: '1' })
        } else {
            panzoom.current?.reset()
            panzoom.current?.resetStyle()
        }
    }, [mappingMode, selectedImageIndex])

    useEffect(() => {
        setDrawings(equipment.drawings)
        if (equipment.partLists?.length) {
            setAllPartList(equipment.partLists.flatMap((partList) => partList.parts))
        } else {
            setAllPartList([])

            setMappingMode(false)
        }
    }, [equipment, equipment.partLists])

    useEffect(() => {
        if (imageLoading) {
            onLoading()
        } else {
            onCompleted()
        }
    }, [imageLoading, onCompleted, onLoading])

    useEffect(() => {
        if (drawings && drawings[selectedImageIndex] && !imageLoading) {
            const imageMapData = JSON.parse(drawings[selectedImageIndex].imageMapData) ?? []
            setAreas(imageMapData)
        }
    }, [drawings, imageLoading, selectedImageIndex])

    useEffect(() => {
        localStorage.setItem('mappingCircle', JSON.stringify(mappingCircle))
        const mappingCircleLocalStorage = JSON.parse(localStorage.getItem('mappingCircle')!)
        if (!mappingCircleLocalStorage) {
            setMappingCircle({ background: EMappingCircleColor.RED, size: EMappingCircleSize.MEDIUM })
            localStorage.setItem('mappingCircle', JSON.stringify({ background: EMappingCircleColor.RED, size: EMappingCircleSize.MEDIUM }))
        }
    }, [mappingCircle])

    const saveMapping = async () => {
        try {
            onLoading()
            const data: EquipmentDrawingInputModel = {
                equipmentId: assemblyId || equipmentId!,
                urls: [equipment.drawings[selectedImageIndex].url],
                imageMapData: JSON.stringify(areas),
            }
            if (equipment.drawings && equipment.drawings[selectedImageIndex]) {
                await EquipmentService.updateDrawingMappingData(equipment.drawings[selectedImageIndex].id, data)
                setMappingMode(false)
                onSuccess(EquipmentActionTypes.UPDATE_MAPPING, undefined)
            }
            setSaveConfirmationVisible(false)
        } catch (error) {
            toast.error(error)
        } finally {
            onCompleted()
        }
    }

    const previousImageClick = () => {
        if (selectedImageIndex !== 0 && selectedImageIndex > 0) {
            onClickPreviousImage()
            setImageLoading(true)
        }
    }

    const nextImageClick = async () => {
        if (equipment.drawings.length - 1 !== selectedImageIndex && selectedImageIndex < equipment.drawings.length - 1) {
            await setImageLoading(true)
            onClickNextImage()
        }
    }

    return (
        <>
            <div className={`drawing-dashboard-container ${classname ?? ''}`}>
                {equipment.drawings && equipment.drawings.length ? (
                    getFileTypeFromUrl(equipment.drawings[selectedImageIndex]?.url) === UploadFileType.Pdf ? (
                        <PdfViewer
                            className="pdf-viewer-sm"
                            file={equipment.drawings[selectedImageIndex].url}
                            type={'sm'}
                            selectedDrawingIndex={selectedImageIndex}
                            previousDrawingAction={previousImageClick}
                            nextDrawingAction={nextImageClick}
                            drawingLength={equipment.drawings.length || 0}
                            setImageLoading={setImageLoading}
                        />
                    ) : (
                        <>
                            <div className="drawing-dashboard-controller" style={{ visibility: 'initial' }}>
                                {!customerSide && (
                                    <div className="left-actions">
                                        {mappingMode ? (
                                            <div className="mapping-mode-buttons">
                                                <Button onClick={saveMapping}>Save</Button>
                                                <Button className="cancel-button" onClick={() => setSaveConfirmationVisible(true)}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        ) : allPartList && allPartList?.length === 0 ? (
                                            <PopoverItem popoverContent="To add hotspots (Interactive Part Mapping), upload a parts list first.">
                                                <span>
                                                    <Button className={`edit-hotspot ${allPartList.length === 0 ? 'disabled' : ''}`}>
                                                        <Icons type={IconType.EditHotspot} />
                                                        <span>Edit Hotspots</span>
                                                    </Button>
                                                </span>
                                            </PopoverItem>
                                        ) : isQuickCart && customEquipment ? (
                                            <PopoverItem popoverContent="To edit this equipment's interactive hotspots, close your active cart.">
                                                <Button className={`edit-hotspot ${isQuickCart ? 'disabled' : ''}`} onClick={() => !isQuickCart && setMappingMode(true)}>
                                                    <Icons type={IconType.EditHotspot} />
                                                    <span>Edit Hotspots</span>
                                                </Button>
                                            </PopoverItem>
                                        ) : (
                                            <Button className="edit-hotspot" onClick={() => setMappingMode(true)}>
                                                <Icons type={IconType.EditHotspot} />
                                                <span>Edit Hotspots</span>
                                            </Button>
                                        )}
                                        <Button className="hotspot-settings" onClick={() => setMappingCircleSettingsVisible(true)}>
                                            <FontAwesomeIcon icon={faGear} />
                                        </Button>
                                    </div>
                                )}
                                <div className="right-actions">
                                    <ZoomButton
                                        onClickPlus={() => {
                                            panzoom.current?.zoomIn()
                                            panzoom.current && setScaleRatio(panzoom.current?.getScale())
                                        }}
                                        onClickMinus={() => {
                                            panzoom.current?.zoomOut()
                                            panzoom.current && panzoom.current?.getScale() > 1 && setScaleRatio(panzoom.current?.getScale())
                                        }}
                                        onClickReset={() => {
                                            panzoom.current?.reset()
                                            panzoom.current && setScaleRatio(panzoom.current?.getScale())
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="drawing-dashboard">
                                <ImageMapping
                                    panzoomRef={imageContainer}
                                    drawings={drawings}
                                    equipment={equipment}
                                    previousImageClick={previousImageClick}
                                    nextImageClick={nextImageClick}
                                    selectedImageIndex={selectedImageIndex}
                                    image={{ src: equipment.drawings[selectedImageIndex].url }}
                                    areas={areas}
                                    setAreas={setAreas}
                                    partLists={allPartList}
                                    mappingMode={mappingMode}
                                    customEquipment={customEquipment}
                                    addToCart={addToCart}
                                    imageLoading={imageLoading}
                                    setImageLoading={setImageLoading}
                                    mappingCircle={mappingCircle}
                                    scaleRatio={scaleRatio}
                                />
                            </div>
                        </>
                    )
                ) : (
                    <div className="no-data">
                        <span className="label">Insert drawing here</span>
                    </div>
                )}
            </div>
            <SaveConfirmationModal
                visible={saveConfirmationVisible}
                onClickClose={() => {
                    if (JSON.parse(equipment.drawings[selectedImageIndex].imageMapData) != null && JSON.parse(equipment.drawings[selectedImageIndex].imageMapData).length > 0) {
                        setAreas(JSON.parse(equipment.drawings[selectedImageIndex].imageMapData))
                    } else setAreas([])
                    setMappingMode(false)
                    setSaveConfirmationVisible(false)
                }}
                onClickCancel={() => {
                    setSaveConfirmationVisible(false)
                }}
                onClickSave={saveMapping}
            />
            <MappingCircleSettings
                mappingCircle={mappingCircle}
                setMappingCircle={(mappingCircle) => setMappingCircle(mappingCircle)}
                visible={mappingCircleSettingsVisible}
                onClose={() => setMappingCircleSettingsVisible(false)}
            />
        </>
    )
}

export default DrawingDashboard
