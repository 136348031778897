import { ProductModel } from '../../models/Ui/Product'
import { CustomPartModel, PartModel } from '../../models/Db/Equipment'
import { CartEquipmentModel } from '../../models/Ui/Cart'
import React, { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { BillModel, CheckoutType, EquipmentCartModel, ShippingModel } from '../../models/Ui/Equipment'
import { OrderModel, snapShotModel } from '../../models/New/Order'
import { CartContextKey } from '../../Config'
import Bugsnag from '@bugsnag/js'
import {
    CustomServiceModel,
    IAddress,
    ICartInformation,
    ICustomPartContextModel,
    ICustomServiceContextModel,
    IEmailRecipients,
    IOrder,
    IOrderRequirements,
    IProductContextModel,
    IQuickCartCustomer,
    ISavedCartInformation,
    IShippingMethodModel,
    TechnicianViewType,
} from '../models'
import { priceFormatter } from '../../utils/helpers'
import { CreateOrderOrQuoteInput } from '../../models/Ui/Order'
import { IAttachmentInfo } from '../../models/Common/AddImageModalVisibleModel'
import TokenService from '../../services/NewServices/TokenService'
import { CompanyType } from '../../models/Ui/CompanyType'
import dayjs from 'dayjs'

interface ICheckoutProps {
    children: ReactNode
}

// main reference model
interface ICheckoutContextModel {
    cartInformation: ICartInformation
    checkoutType: CheckoutType
    savedCardInformation: ISavedCartInformation
    order: IOrder
    requirements: IOrderRequirements
    quickCartCustomer?: IQuickCartCustomer
    purchaseWithoutCustomer: boolean
    customerNameOfPurchaseWithoutCustomer: string
}

export const getInitialModel = (): ICheckoutContextModel => {
    return {
        requirements: {
            emailRecipients: {
                emails: [],
                note: '',
            },
            billingAddress: {
                note: '',
                city: '',
                site_address1: '',
                site_address2: '',
                country: '',
                state: '',
                postal_code: '',
                contact_person: '',
                phone_number: '',
            } as IAddress,
            isSameAddress: false,
            shippingAddress: {
                note: '',
                city: '',
                site_address1: '',
                site_address2: '',
                country: '',
                state: '',
                contact_person: '',
                phone_number: '',
            } as IAddress,
            shippingMethod: {
                note: '',
                shipVIA: '',
                type: '',
            } as IShippingMethodModel,
        },
        order: {
            products: [],
            equipments: [],
            customParts: [],
            customServices: [],
        },
        cartInformation: {
            show: false,
            quickCartShow: false,
            technicianViewType: TechnicianViewType.None,
            prevCartShow: false,
            purchaseForCustomerName: '',
            purchaseForCustomerId: '',
            orderId: '',
            isManuallyPurchaseForCustomer: false,
            isQuote: false,
        },
        checkoutType: CheckoutType.Purchase,
        savedCardInformation: {
            name: '',
            id: '',
        },
        quickCartCustomer: { id: '', name: '' },
        purchaseWithoutCustomer: false,
        customerNameOfPurchaseWithoutCustomer: '',
    } as ICheckoutContextModel
}

function useContext() {
    const checkoutStorage = localStorage.getItem(CartContextKey)

    let contextModel = {} as ICheckoutContextModel
    if (checkoutStorage) {
        try {
            contextModel = JSON.parse(checkoutStorage) as ICheckoutContextModel
        } catch (e: any) {
            Bugsnag.notify(e)
            localStorage.removeItem(CartContextKey)
            contextModel = getInitialModel()
        }
    } else {
        contextModel = getInitialModel()
    }

    const [clear, setClear] = useState(false)

    const [shippingAddress, setShippingAddress] = useState<IAddress>(contextModel.requirements.shippingAddress)

    const [shippingMethod, setShippingMethod] = useState<IShippingMethodModel>(contextModel.requirements.shippingMethod)

    const [billingAddress, setBillingAddress] = useState<IAddress>(contextModel.requirements.billingAddress)

    const [isSameAddress, setIsSameAddress] = useState<boolean>(contextModel.requirements.isSameAddress)

    const [recipientsEmails, setRecipientsEmails] = useState<IEmailRecipients>(contextModel.requirements.emailRecipients)

    const [checkoutType, setCheckoutType] = useState<CheckoutType>(contextModel.checkoutType)

    const [contextProducts, setContextProducts] = useState<IProductContextModel[]>(contextModel.order.products)

    const [contextEquipments, setContextEquipments] = useState<CartEquipmentModel[]>(contextModel.order.equipments)

    const [contextCustomParts, setContextCustomParts] = useState<ICustomPartContextModel[]>(contextModel.order.customParts)

    const [contextCustomServices, setContextCustomServices] = useState<ICustomServiceContextModel[]>(contextModel.order.customServices)

    const [savedCartInformation, setSavedOrderInformation] = useState<ISavedCartInformation>(contextModel.savedCardInformation)

    const [cartInformation, setCartInformation] = useState<ICartInformation>(contextModel.cartInformation)

    const [quickCartInformation, setQuickCartInformation] = useState<ISavedCartInformation>(contextModel.savedCardInformation)

    const [purchaseWithoutCustomer, setPurchaseWithoutCustomer] = useState<boolean>(contextModel.purchaseWithoutCustomer)

    const [customerNameOfPurchaseWithoutCustomer, setCustomerNameOfPurchaseWithoutCustomer] = useState<string>(contextModel.customerNameOfPurchaseWithoutCustomer)
    const [reOrderNotes, setReOrderNotes] = useState<string | undefined>(undefined)
    useEffect(() => {
        saveToLocalStorage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        clear,
        shippingAddress,
        shippingMethod,
        billingAddress,
        isSameAddress,
        recipientsEmails,
        checkoutType,
        contextProducts,
        contextEquipments,
        contextCustomParts,
        savedCartInformation,
        cartInformation,
        contextCustomServices,
        quickCartInformation,
        customerNameOfPurchaseWithoutCustomer,
        purchaseWithoutCustomer,
    ])
    // gereksiz gibi duruyor. Kullanilmazsa silinebilir.
    // disaridan okunabilir olmamali.
    // buradaki degeri public bir metot ile servis edilmeli.

    useEffect(() => {
        console.log(purchaseWithoutCustomer, 'purchaseWithoutCustomer')
    }, [purchaseWithoutCustomer])

    const [order, setOrder] = useState<OrderModel>()

    const fillOrder = async (args: OrderModel) => {
        reset()
        setOrder((prevState) => ({
            ...prevState,
            ...args,
        }))
        setReOrderNotes(args.reOrderNotes)
    }

    const getAttachments = (snapshot: snapShotModel) => {
        let attachments: IAttachmentInfo[] = []

        if (snapshot.attachment) {
            attachments.push(snapshot.attachment)
        } else if (Array.isArray(snapshot.attachments)) {
            attachments = [...snapshot.attachments]
        }

        return attachments
    }

    useEffect(() => {
        // clear
        // setContextProducts([])
        // setContextEquipments([])
        // setContextCustomParts([])

        if (!order) return

        let parts: { equipment: EquipmentCartModel; part: PartModel }[] = []
        let products: ProductModel[] = []
        let customParts: CustomPartModel[] = []
        let customServices: CustomServiceModel[] = []
        if (order.orderDetails.some((orderDetails) => orderDetails !== null)) {
            for (let orderDetailsIndex = 0; orderDetailsIndex < order.orderDetails.length; orderDetailsIndex++) {
                const orderDetailsModel = order.orderDetails[orderDetailsIndex]
                const snapShotItems: snapShotModel[] = JSON.parse(orderDetailsModel.snapShot)

                for (let index = 0; index < snapShotItems.length; index++) {
                    const item = snapShotItems[index]
                    if (item.part_id) {
                        const equipment: EquipmentCartModel = {
                            savedId: orderDetailsModel.id,
                            savedOrderId: orderDetailsModel.orderId,
                            id: item.equipment_id || '',
                            name: item.equipment_name,
                            partNumber: item.part_number,
                            manufacturer: {
                                name: orderDetailsModel.manufacturerName,
                                id: orderDetailsModel.manufacturerId,
                                email: orderDetailsModel.manufacturerEmail,
                            },
                        }
                        const part: PartModel = {
                            index: index,
                            no: item.part_id,
                            serialNo: item.serial_no,
                            part_no: item.part_number,
                            name: item.name,
                            note: item.notes,
                            leadTime: item.part_id,
                            pkg: item.pkg,
                            uom: item.uom,
                            qty: item.quantity,
                            category: item.category,
                            vendor: item.brand,
                            price: item.price,
                            adjustedPrice: item.adjust_price,
                            id: item.part_id,
                            description: '',
                            warrantyEnds: item.warranty_ends,
                            warrantyStatus: item.part_id,
                            warrantyDuration: item.warranty_duration,
                            warrantyDurationUnit: item.warranty_duration_unit,
                            warrantyStarts: item.warranty_starts,
                            attachments: getAttachments(item),
                        }
                        parts.push({ equipment, part })
                    } else if (item.product_id) {
                        const product: ProductModel = {
                            savedId: orderDetailsModel.id,
                            savedOrderId: orderDetailsModel.orderId,
                            manufacturer: {
                                name: orderDetailsModel.manufacturerId,
                                id: orderDetailsModel.manufacturerName,
                                email: orderDetailsModel.manufacturerEmail,
                            },
                            part_no: item.part_number,
                            name: item.name,
                            note: item.notes,
                            pkg: item.pkg,
                            uom: item.uom,
                            qty: item.quantity,
                            category: item.category,
                            vendor: item.brand,
                            price: item.price,
                            adjustedPrice: item.adjust_price,
                            id: item.product_id,
                            attachments: getAttachments(item),
                        }
                        products.push(product)
                        //checkoutContext.addProduct(product)
                    } else {
                        if (item.part_number) {
                            const customPart: CustomPartModel = {
                                id: orderDetailsModel.id,
                                savedId: orderDetailsModel.id,
                                savedOrderId: orderDetailsModel.id,
                                manufacturer: {
                                    name: orderDetailsModel.manufacturerName,
                                    id: orderDetailsModel.manufacturerId,
                                },
                                part_no: item.part_number,
                                name: item.name,
                                qty: item.quantity,
                                note: item.notes,
                                price: item.price,
                                serial_no: item.serial_no,
                                equipment_name: item.equipment_name,
                                attachments: getAttachments(item),
                            }
                            customParts.push(customPart)
                        } else {
                            const customService: CustomServiceModel = {
                                id: orderDetailsModel.id,
                                savedId: orderDetailsModel.id,
                                savedOrderId: orderDetailsModel.id,
                                attachments: getAttachments(item),
                                name: item.name,
                                note: item.notes,
                                price: item.price,
                                manufacturerId: orderDetailsModel.manufacturerId,
                                qty: item.quantity,
                            }
                            customServices.push(customService)
                        }
                    }
                }
            }
        }

        addAdditionalItems(customParts, true)
        addParts(parts, true, true)
        addProducts(products, true, true)
        addAdditionalServiceItems(customServices, true)

        //  cartInformation: ICartInformation
        // checkoutType: CheckoutSavedType
        // savedCardInformation: ISavedOrderInformation
        // order: IOrder
        // requirements: IOrderRequirements

        if (JSON.parse(order.billingAddress) === JSON.parse(order.shippingAddress)) {
            setIsSameAddress(true)
        }

        setBillingAddress(JSON.parse(order.billingAddress) as IAddress)
        setShippingAddress(JSON.parse(order.shippingAddress) as IAddress)
        setShippingMethod({
            shipVIA: order.shipVia,
            type: order.shippingType,
            note: order.shippingNotes,
        } as IShippingMethodModel)

        setRecipientsEmails({
            note: order.messageToRecipients,
            emails: order.recipientEmails,
        } as IEmailRecipients)
        if (order.isSaved) {
            setSavedOrderInformation({
                name: order.savedAs,
                id: order.id,
            } as ISavedCartInformation)
        } else {
            setSavedOrderInformation({
                // name: `Saved cart ${standartDate(new Date())}`,
                name: '',
                id: '',
            } as ISavedCartInformation)
        }

        setCheckoutType(order.checkoutType === CheckoutType.Quote ? CheckoutType.Quote : CheckoutType.Purchase)

        saveToLocalStorage()

        setCartInformation({
            ...cartInformation,
            show: !cartInformation.show ?? cartInformation.show,
            purchaseForCustomerId: order.customerId,
            purchaseForCustomerName: order.customer,
            orderId: order.id,
            technicianViewType: TechnicianViewType.PurchaseAs,
            isQuote: order.isQuote!,
            quoteId: order.isQuote ? order.id : undefined,
        })

        if (!order.customerId) {
            setPurchaseWithoutCustomer(true)
            setCustomerNameOfPurchaseWithoutCustomer(order.customer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    const saveToLocalStorage = () => {
        // save to localstorage
        const contextModel: ICheckoutContextModel = {
            cartInformation,
            checkoutType: checkoutType,
            requirements: {
                emailRecipients: recipientsEmails,
                isSameAddress,
                shippingMethod,
                billingAddress,
                shippingAddress,
            } as IOrderRequirements,
            order: {
                products: contextProducts,
                customParts: contextCustomParts,
                equipments: contextEquipments,
                customServices: contextCustomServices,
            } as IOrder,
            savedCardInformation: savedCartInformation,
            quickCartCustomer: quickCartInformation,
            purchaseWithoutCustomer: purchaseWithoutCustomer,
            customerNameOfPurchaseWithoutCustomer: customerNameOfPurchaseWithoutCustomer,
        }

        localStorage.setItem(CartContextKey, JSON.stringify(contextModel))
    }

    const addProducts = (products: ProductModel[], isQuantity?: boolean, reset: boolean = false) => {
        const productIds = reset ? [] : contextProducts.map((p) => p.product.id)
        let newProducts = reset ? [] : [...contextProducts]

        for (let i = 0; i < products.length; i++) {
            const product = products[i]

            if (productIds.includes(product.id)) {
                const productIndex = newProducts.findIndex((p) => p.product.id === product.id)
                newProducts[productIndex] = { ...newProducts[productIndex], count: newProducts[productIndex].count + 1 }
            } else {
                newProducts = [
                    ...(reset ? [] : [...contextProducts]),
                    {
                        count: isQuantity ? JSON.parse(product.qty as string) : 1,
                        product: product,
                    },
                ]
            }
        }

        setContextProducts(newProducts)
    }

    const addAdditionalItems = (item: CustomPartModel[], reset: boolean = false) => {
        let newCustomParts = reset ? [] : [...contextCustomParts]
        for (let i = 0; i < item.length; i++) {
            const customPart = item[i]
            newCustomParts = [...contextCustomParts, { count: customPart.qty, customPart: customPart }]
        }
        setContextCustomParts([...newCustomParts])
    }
    const addAdditionalServiceItems = (item: CustomServiceModel[], reset: boolean = false) => {
        let newCustomServices = reset ? [] : [...contextCustomServices]
        for (let i = 0; i < item.length; i++) {
            const customService = item[i]
            newCustomServices = [...contextCustomServices, { count: customService.qty, customService: customService }]
        }
        setContextCustomServices([...newCustomServices])
    }

    const addParts = (input: { equipment: EquipmentCartModel; part: PartModel }[], isQuantity?: boolean, reset: boolean = false) => {
        let contextEquipmentsTemp = reset ? [] : contextEquipments
        for (let i = 0; i < input.length; i++) {
            const { equipment, part } = input[i]
            if (contextEquipmentsTemp.map((p) => p.equipment.id).includes(equipment.id)) {
                const eq = contextEquipmentsTemp.find((p) => p.equipment.id === equipment.id)!
                const partId = eq.parts.map((p) => p.part.id)
                if (partId.includes(part.id)) {
                    const equipmentIndex = contextEquipmentsTemp.findIndex((p) => p.equipment.id === equipment.id)
                    const newParts = [...contextEquipmentsTemp[equipmentIndex].parts]
                    const partIndex = newParts.findIndex((p) => p.part.id === part.id)
                    newParts[partIndex] = { ...newParts[partIndex], count: newParts[partIndex].count + 1 }
                    contextEquipmentsTemp[equipmentIndex] = {
                        ...contextEquipmentsTemp[equipmentIndex],
                        parts: newParts,
                    }
                } else {
                    const equipmentIndex = contextEquipmentsTemp.findIndex((p) => p.equipment.id === equipment.id)
                    const newParts = [...contextEquipmentsTemp[equipmentIndex].parts]
                    newParts.push({ count: isQuantity ? JSON.parse(part.qty as string) : 1, part })
                    contextEquipmentsTemp[equipmentIndex] = {
                        ...contextEquipmentsTemp[equipmentIndex],
                        parts: newParts,
                    }
                }
            } else {
                contextEquipmentsTemp.push({
                    equipment,
                    parts: [{ count: isQuantity ? JSON.parse(part.qty as string) : 1, part }],
                })
            }
        }

        setContextEquipments([...contextEquipmentsTemp])
    }

    const setSavedCartInfo = (info: ISavedCartInformation) => {
        setSavedOrderInformation({
            ...savedCartInformation,
            ...info,
        })
    }

    const setQuickCartInfo = (info: ISavedCartInformation) => {
        if (info.id === '' && info.name === '') {
            setQuickCartInformation({
                ...quickCartInformation,
                ...info,
            })
            setContextEquipments([])
            setContextProducts([])
            setContextCustomParts([])
            setContextCustomServices([])
            setSavedCartInfo({
                id: '',
                name: '',
            })
        }
        setQuickCartInformation({
            ...quickCartInformation,
            ...info,
        })
    }
    const handlePurchaseForName = (name: string) => {
        setCartInformation({
            ...cartInformation,
            purchaseForCustomerName: name,
        })
    }

    const handleCheckoutType = (value: CheckoutType) => {
        setCheckoutType(value)
    }
    const handleShippingAddress = (value: BillModel) => {
        setShippingAddress({ ...shippingAddress, ...value })
    }
    const handleBillingAddress = (value: BillModel) => {
        setBillingAddress({ ...billingAddress, ...value })
    }
    const handleShippingMethod = (value: ShippingModel) => {
        setShippingMethod({ ...shippingMethod, ...value })
    }

    const handleRecipientsEmails = (input: IEmailRecipients) => {
        setRecipientsEmails(input)
    }

    const handleIsSameAddress = (isSameAddress: boolean) => {
        setIsSameAddress(isSameAddress)
        if (isSameAddress) {
            setBillingAddress({
                ...billingAddress,
                site_address1: shippingAddress.site_address1,
                site_address2: shippingAddress.site_address2,
                city: shippingAddress.city,
                state: shippingAddress.state,
                postal_code: shippingAddress.postal_code,
                country: shippingAddress.country,
            })
            return
        }
        setBillingAddress(getInitialModel().requirements.billingAddress)
    }
    const clearAll = async () => {
        // clear localstorage
        // localStorage.removeItem(CartContextKey)

        // set initial states
        // const model = getInitialModel()
        // set initial states to localstorage
        // localStorage.setItem(CartContextKey, JSON.stringify(model))
        // setContextModel(model)

        setClear(!clear)
    }
    const reset = () => {
        const defaultModel = getInitialModel()
        setContextEquipments([])
        setContextCustomServices([])
        setContextCustomParts([])
        setContextProducts([])
        setCartInformation(defaultModel.cartInformation)
        setSavedOrderInformation(defaultModel.savedCardInformation)
        setShippingAddress(defaultModel.requirements.shippingAddress)
        setBillingAddress(defaultModel.requirements.billingAddress)
        setRecipientsEmails(defaultModel.requirements.emailRecipients)
        setShippingMethod(defaultModel.requirements.shippingMethod)
        localStorage.removeItem(CartContextKey)
        setClear(!clear)
    }

    const quickCartReset = () => {
        const defaultModel = getInitialModel()
        setPurchaseWithoutCustomer(defaultModel.purchaseWithoutCustomer)
        setCustomerNameOfPurchaseWithoutCustomer(defaultModel.customerNameOfPurchaseWithoutCustomer)
    }

    const handleCartInformation = async (info: ICartInformation) => {
        const defaultModel = getInitialModel()
        if (
            (info.purchaseForCustomerId && cartInformation.purchaseForCustomerId !== info.purchaseForCustomerId) ||
            (info.technicianViewType && info.technicianViewType !== cartInformation.technicianViewType) ||
            (info.technicianViewType && info.technicianViewType !== cartInformation.technicianViewType)
        ) {
            await reset()
            setSavedCartInfo({ ...defaultModel.savedCardInformation, name: `Saved cart ${dayjs(new Date()).format('MM/DD/YYYY')}` })
        }
        if (info.technicianViewType && info.technicianViewType === TechnicianViewType.Quick) {
            setCartInformation({ ...defaultModel.cartInformation, ...info, show: false })
            if (purchaseWithoutCustomer) {
                setSavedCartInfo({
                    name: 'Cart',
                } as ISavedCartInformation)
            }
        } else {
            setCartInformation({
                ...cartInformation,
                ...info,
            })
        }
        if (!info.isManuallyPurchaseForCustomer) {
            setPurchaseWithoutCustomer(false)
            setCustomerNameOfPurchaseWithoutCustomer('')
        }
    }

    const handleCustomerNameOfPurchaseWithoutCustomer = (name: string) => {
        setCustomerNameOfPurchaseWithoutCustomer(name)
        setCartInformation({ ...cartInformation, purchaseForCustomerName: name } as ICartInformation)
        setSavedCartInfo({
            name: 'Cart',
        } as ISavedCartInformation)
    }

    const addPart = async (
        equipment: EquipmentCartModel,
        part: PartModel,
        customerInfo?: {
            id: string
            name: string
        }
    ) => {
        switch (cartInformation.technicianViewType) {
            case TechnicianViewType.Quick:
                if (!purchaseWithoutCustomer && getCount() === 0 && customerInfo) {
                    setQuickCartInfo({
                        id: customerInfo.id,
                        name: customerInfo.name,
                    } as IQuickCartCustomer)
                    handleCartInformation({
                        purchaseForCustomerId: customerInfo.id,
                        purchaseForCustomerName: customerInfo.name,
                    } as ICartInformation)
                }

                break
            default:
                break
        }
        await addParts([{ equipment, part }])
        if (!cartInformation.prevCartShow && cartInformation.technicianViewType !== TechnicianViewType.Quick) {
            setCartInformation({
                ...cartInformation,
                show: true,
                prevCartShow: true,
            })
        } else if (!cartInformation.prevCartShow && !cartInformation.quickCartShow) {
            setCartInformation({
                ...cartInformation,
                prevCartShow: true,
                quickCartShow: true,
            })
        }
    }
    const addAdditionalItem = (item: CustomPartModel) => {
        addAdditionalItems([item])
    }
    const addAdditionalServiceItem = (item: CustomServiceModel) => {
        addAdditionalServiceItems([item])
    }

    const removeAdditionalItem = (item: CustomPartModel) => {
        const newItems = [...contextCustomParts]
        const currentCustomItems = newItems.filter((p) => p.customPart !== item)
        setContextCustomParts([...currentCustomItems])
    }

    const removeAdditionalServiceItem = (item: CustomServiceModel) => {
        const newItems = [...contextCustomServices]
        const currentCustomItems = newItems.filter((p) => p.customService !== item)
        setContextCustomServices([...currentCustomItems])
    }

    const addProduct = (product: ProductModel) => {
        addProducts([product])
        if (!cartInformation.prevCartShow && cartInformation.technicianViewType !== TechnicianViewType.Quick) {
            setCartInformation({
                ...cartInformation,
                show: true,
                prevCartShow: true,
            })
        } else {
            setCartInformation({
                ...cartInformation,
                quickCartShow: true,
            })
        }
    }
    const removePart = (equipment: EquipmentCartModel, part: PartModel) => {
        const equipmentIndex = contextEquipments.findIndex((p) => p.equipment.id === equipment.id)
        const newParts = [...contextEquipments[equipmentIndex].parts]
        const partIndex = newParts.findIndex((p) => p.part.id === part.id)
        if (newParts[partIndex].count > 1) {
            newParts[partIndex] = { ...newParts[partIndex], count: newParts[partIndex].count - 1 }
            const newEquipments = [...contextEquipments]
            newEquipments[equipmentIndex] = { ...newEquipments[equipmentIndex], parts: newParts }
            setContextEquipments([...newEquipments])
        }
    }
    const removeProduct = (product: ProductModel) => {
        const productIndex = contextProducts.findIndex((p) => p.product.id === product.id)
        const newProducts = [...contextProducts]
        if (newProducts[productIndex].count > 1) {
            newProducts[productIndex] = { ...newProducts[productIndex], count: newProducts[productIndex].count - 1 }
            setContextProducts([...newProducts])
        }
    }

    const setPartCount = (equipment: EquipmentCartModel, part: PartModel, count: number) => {
        if (count > 0) {
            const newEquipments = [...contextEquipments]
            const equipmentIndex = newEquipments.findIndex((p) => p.equipment.id === equipment.id)
            const partIndex = newEquipments[equipmentIndex].parts.findIndex((p) => p.part.id === part.id)
            newEquipments[equipmentIndex].parts[partIndex].count = count
            setContextEquipments([...newEquipments])
        }
    }
    const setAdditionalItemCount = (additionalPart: CustomPartModel, count: number) => {
        if (count > 0) {
            const newCustomParts = [...contextCustomParts]
            const productIndex = newCustomParts.findIndex((p) => p.customPart === additionalPart)
            newCustomParts[productIndex].count = count
            setContextCustomParts([...newCustomParts])
        }
    }
    const setAdditionalServiceItemCount = (additionalService: CustomServiceModel, count: number) => {
        if (count > 0) {
            const newCustomServices = [...contextCustomServices]
            const productIndex = newCustomServices.findIndex((p) => p.customService === additionalService)
            newCustomServices[productIndex].count = count
            setContextCustomServices([...newCustomServices])
        }
    }

    const setProductCount = (product: ProductModel, count: number) => {
        if (count > 0) {
            const newProducts = [...contextProducts]
            const productIndex = newProducts.findIndex((p) => p.product.id === product.id)
            newProducts[productIndex].count = count
            setContextProducts([...newProducts])
        }
    }

    const getGroupedItemsByManufacturer = () => {
        const groupedItems = {} as any
        contextEquipments.forEach((equipment: any) => {
            const manufacturer = equipment.equipment.manufacturer.id
            if (groupedItems[manufacturer]) {
                groupedItems[manufacturer].equipments.push(equipment)
            } else {
                groupedItems[manufacturer] = { equipments: [equipment], products: [], customParts: [] }
            }
        })
        contextProducts.forEach((productItem: any) => {
            const manufacturer = productItem.product.id
            if (groupedItems[manufacturer]) {
                groupedItems[manufacturer].products.push(productItem)
            } else {
                groupedItems[manufacturer] = { equipments: [], products: [productItem], customParts: [] }
            }
        })
        contextCustomParts.forEach((customPart: any) => {
            const manufacturer = customPart.manufacturer?.id
            if (groupedItems[manufacturer]) {
                groupedItems[manufacturer].customParts.push(customPart)
            } else {
                groupedItems[manufacturer] = { equipments: [], products: [], customParts: [customPart] }
            }
        })
        return groupedItems as any
    }

    const getTotalPrice = () => {
        const currentEqTotalPrice = contextEquipments.map((e) => {
            return e.parts.reduce((acc, item) => {
                let adjustPrice = item.part.adjustedPrice || 0
                return acc + adjustPrice * item.count
            }, 0)
        })
        const currentProductTotalPrice = contextProducts.reduce((acc, p) => {
            let adjustPrice = p.product.adjustedPrice || 0
            return acc + adjustPrice * p.count
        }, 0)

        const currentCustomPartTotalPrice = contextCustomParts.reduce((acc, p) => {
            return acc + (p.customPart.price !== '-' ? p.customPart.price : 0) * p.count
        }, 0)

        const currentCustomServiceTotalPrice = contextCustomServices.reduce((acc, p) => {
            return acc + (p.customService.price !== '-' ? p.customService.price : 0) * p.count
        }, 0)

        const sumAllProduct =
            currentEqTotalPrice.reduce((acc, item) => {
                return acc + item
            }, 0) +
            currentProductTotalPrice +
            currentCustomPartTotalPrice +
            currentCustomServiceTotalPrice

        return priceFormatter(sumAllProduct)
    }

    const clearPart = (equipment: EquipmentCartModel, part: PartModel) => {
        const equipmentIndex = contextEquipments.findIndex((p) => p.equipment.id === equipment.id)
        const newParts = [...contextEquipments[equipmentIndex].parts]
        const partIndex = newParts.findIndex((p) => p.part.id === part.id)
        newParts.splice(partIndex, 1)
        const newEquipments = [...contextEquipments]
        if (newParts.length === 0) {
            newEquipments.splice(equipmentIndex, 1)
        } else {
            newEquipments[equipmentIndex] = { ...newEquipments[equipmentIndex], parts: newParts }
        }
        setContextEquipments([...newEquipments])
    }
    const clearProduct = (product: ProductModel) => {
        const productIndex = contextProducts.findIndex((p) => p.product.id === product.id)
        const newProducts = [...contextProducts]
        newProducts.splice(productIndex, 1)
        setContextProducts([...newProducts])
    }
    const getCount = () => {
        let count = 0
        contextProducts.forEach((p) => (count += p.count))
        contextCustomParts.forEach((p) => (count += p.count))
        contextCustomServices.forEach((p) => (count += p.count))
        contextEquipments.forEach((e) => e.parts.forEach((p) => (count += p.count)))
        return count
    }
    const setPartNote = (equipment: EquipmentCartModel, part: PartModel, note: string) => {
        const newEquipments = [...contextEquipments]
        const equipmentIndex = newEquipments.findIndex((p) => p.equipment.id === equipment.id)
        const partIndex = newEquipments[equipmentIndex].parts.findIndex((p) => p.part.id === part.id)
        newEquipments[equipmentIndex].parts[partIndex].part.note = note
        setContextEquipments([...newEquipments])
    }
    const setPartPrice = (equipment: EquipmentCartModel, part: PartModel, price: number) => {
        const newEquipments = [...contextEquipments]
        const equipmentIndex = newEquipments.findIndex((p) => p.equipment.id === equipment.id)
        const partIndex = newEquipments[equipmentIndex].parts.findIndex((p) => p.part.id === part.id)
        newEquipments[equipmentIndex].parts[partIndex].part.adjustedPrice = price
        setContextEquipments([...newEquipments])
    }

    const setProductPrice = (product: ProductModel, price: number) => {
        const newProducts = [...contextProducts]
        const productIndex = newProducts.findIndex((p) => p.product.id === product.id)
        newProducts[productIndex].product.adjustedPrice = price
        setContextProducts([...newProducts])
    }

    const setPartAttachment = (equipment: EquipmentCartModel, part: PartModel, attachments: IAttachmentInfo[]) => {
        const newEquipments = [...contextEquipments]
        const equipmentIndex = newEquipments.findIndex((p) => p.equipment.id === equipment.id)
        const partIndex = newEquipments[equipmentIndex].parts.findIndex((p) => p.part.id === part.id)
        newEquipments[equipmentIndex].parts[partIndex].part.attachments = [...attachments]
        setContextEquipments([...newEquipments])
    }
    const setProductNote = (product: ProductModel, note: string) => {
        const newProducts = [...contextProducts]
        const productIndex = newProducts.findIndex((p) => p.product.id === product.id)
        newProducts[productIndex].product.note = note
        setContextProducts([...newProducts])
    }
    const setProductAttachment = (product: ProductModel, attachments: IAttachmentInfo[]) => {
        const newProducts = [...contextProducts]
        const productIndex = newProducts.findIndex((p) => p.product.id === product.id)
        newProducts[productIndex].product.attachments = [...attachments]
        setContextProducts([...newProducts])
    }

    const setCustomPartAttachment = (part: CustomPartModel, attachments: IAttachmentInfo[]) => {
        const newCustomParts = [...contextCustomParts]
        const customPartIndex = newCustomParts.findIndex((p) => p.customPart === part)
        newCustomParts[customPartIndex].customPart.attachments = [...attachments]
        setContextCustomParts([...newCustomParts])
    }
    const setCustomServiceAttachment = (part: CustomServiceModel, attachments: IAttachmentInfo[]) => {
        const newCustomServices = [...contextCustomServices]
        const customServiceIndex = newCustomServices.findIndex((p) => p.customService === part)
        newCustomServices[customServiceIndex].customService.attachments = [...attachments]
        setContextCustomServices([...newCustomServices])
    }

    const setAdditionalItemPrice = (additionalPart: CustomPartModel, price: number) => {
        const newCustomParts = [...contextCustomParts]
        const customPartIndex = newCustomParts.findIndex((p) => p.customPart === additionalPart)
        newCustomParts[customPartIndex].customPart.price = price
        setContextCustomParts([...newCustomParts])
    }

    const setAdditionalServiceItemPrice = (additionalService: CustomServiceModel, price: number) => {
        const newCustomServices = [...contextCustomServices]
        const customServiceIndex = newCustomServices.findIndex((p) => p.customService === additionalService)
        newCustomServices[customServiceIndex].customService.price = price
        setContextCustomServices([...newCustomServices])
    }

    const setAdditionalItem = (id: string, newAdditionalPart: CustomPartModel) => {
        const newCustomParts = [...contextCustomParts]
        const productIndex = newCustomParts.findIndex((p) => p.customPart.id === id)
        newCustomParts[productIndex].customPart = newAdditionalPart
        newCustomParts[productIndex].count = Number(newAdditionalPart.qty)
        setContextCustomParts([...newCustomParts])
    }
    const setAdditionalService = (id: string, newAdditionalService: CustomServiceModel) => {
        const newCustomServices = [...contextCustomServices]
        const productIndex = newCustomServices.findIndex((p) => p.customService.id === id)
        newCustomServices[productIndex].customService = newAdditionalService
        setContextCustomServices([...newCustomServices])
    }

    const addCustomPart = (additionalPart: CustomPartModel) => {
        const customPart = contextCustomParts.map((p) => p.customPart)

        if (customPart.includes(additionalPart)) {
            const newCustomParts = [...contextCustomParts]
            const customPartsIndex = newCustomParts.findIndex((p) => p.customPart === additionalPart)
            newCustomParts[customPartsIndex] = {
                ...newCustomParts[customPartsIndex],
                count: newCustomParts[customPartsIndex].count + 1,
            }
            setContextCustomParts([...newCustomParts])
        }
    }
    const addCustomService = (additionalService: CustomServiceModel) => {
        const customService = contextCustomServices.map((p) => p.customService)

        if (customService.includes(additionalService)) {
            const newCustomServices = [...contextCustomServices]
            const customServicesIndex = newCustomServices.findIndex((p) => p.customService === additionalService)
            newCustomServices[customServicesIndex] = {
                ...newCustomServices[customServicesIndex],
                count: newCustomServices[customServicesIndex].count + 1,
            }
            setContextCustomServices([...newCustomServices])
        }
    }

    const removeCustomPart = (additionalPart: CustomPartModel) => {
        const customPartIndex = contextCustomParts.findIndex((p) => p.customPart === additionalPart)
        const newCustomParts = [...contextCustomParts]
        if (newCustomParts[customPartIndex].count > 1) {
            newCustomParts[customPartIndex] = {
                ...newCustomParts[customPartIndex],
                count: newCustomParts[customPartIndex].count - 1,
            }
            setContextCustomParts([...newCustomParts])
        }
    }
    const removeCustomService = (additionalService: CustomServiceModel) => {
        const customServiceIndex = contextCustomServices.findIndex((p) => p.customService === additionalService)
        const newCustomServices = [...contextCustomServices]
        if (newCustomServices[customServiceIndex].count > 1) {
            newCustomServices[customServiceIndex] = {
                ...newCustomServices[customServiceIndex],
                count: newCustomServices[customServiceIndex].count - 1,
            }
            setContextCustomServices([...newCustomServices])
        }
    }

    const toggleCartView = (value?: boolean) => {
        const show = cartInformation.show

        if ((value || !show) && cartInformation.quickCartShow) {
            setCartInformation({ ...cartInformation, quickCartShow: false, show: value || !show })
        } else {
            setCartInformation({
                ...cartInformation,
                show: value || !show,
            })
        }
    }
    const toggleQuickCartView = (value?: boolean) => {
        const show = cartInformation.quickCartShow

        if ((value || !show) && cartInformation.show) {
            setCartInformation({ ...cartInformation, show: false, quickCartShow: value || !show })
        } else {
            setCartInformation({
                ...cartInformation,
                quickCartShow: value || !show,
            })
        }
    }

    const createCheckoutPayload = (userId: string, type: CheckoutType) => {
        const getSummaryData = () => {
            let orderDetails: {
                id?: string
                orderId?: string
                manufacturerId?: string
                notes: string
                snapShot: [
                    {
                        adjust_price: number
                        brand?: string
                        category?: string
                        uom?: string
                        pkg?: string
                        equipment_id?: string
                        equipment_name?: string
                        part_id?: string
                        product_id?: string
                        part_number: string
                        serial_no?: string
                        name: string
                        price: number | string
                        quantity: number | string
                        notes?: string
                        warranty_duration?: number
                        warranty_duration_unit?: string
                        warranty_starts?: string
                        warranty_ends?: string
                        attachments: IAttachmentInfo[]
                    }
                ]
            }[] = []
            contextEquipments.forEach((e) => {
                e.parts.forEach((p, i) => {
                    if (orderDetails.find((item) => item.manufacturerId === e.equipment.manufacturer?.id)) {
                        let newData = orderDetails.find((item) => {
                            return item.manufacturerId === e.equipment.manufacturer?.id
                        })
                        newData?.snapShot.push({
                            equipment_id: e.equipment.id,
                            equipment_name: e.equipment.name,
                            part_id: p.part.id,
                            part_number: p.part.part_no,
                            serial_no: p.part.serialNo,
                            name: p.part.name,
                            quantity: p.count,
                            adjust_price: p.part.adjustedPrice,
                            price: p.part.price,
                            notes: p.part.note,
                            uom: p.part.uom,
                            pkg: p.part.pkg,
                            category: p.part.category,
                            brand: p.part.vendor,
                            warranty_duration: p.part.warrantyDuration,
                            warranty_duration_unit: p.part.warrantyDurationUnit,
                            warranty_starts: p.part.warrantyStarts,
                            warranty_ends: p.part.warrantyEnds,
                            attachments: p.part.attachments,
                        })
                    } else {
                        orderDetails.push({
                            id: e.equipment.savedId,
                            orderId: e.equipment.savedOrderId,
                            manufacturerId: e.equipment.manufacturer?.id,
                            notes: '',
                            snapShot: [
                                {
                                    equipment_id: e.equipment.id,
                                    equipment_name: e.equipment.name,
                                    part_id: p.part.id,
                                    part_number: p.part.part_no,
                                    serial_no: p.part.serialNo,
                                    name: p.part.name,
                                    quantity: p.count,
                                    adjust_price: p.part.adjustedPrice,
                                    price: p.part.price,
                                    notes: p.part.note,
                                    uom: p.part.uom,
                                    pkg: p.part.pkg,
                                    category: p.part.category,
                                    brand: p.part.vendor,
                                    warranty_duration: p.part.warrantyDuration,
                                    warranty_duration_unit: p.part.warrantyDurationUnit,
                                    warranty_starts: p.part.warrantyStarts,
                                    warranty_ends: p.part.warrantyEnds,
                                    attachments: p.part.attachments,
                                },
                            ],
                        })
                    }
                })
            })
            contextProducts.forEach((e) => {
                if (orderDetails.find((item) => item.manufacturerId === e.product.manufacturer.id)) {
                    let newData = orderDetails.find((item) => {
                        return item.manufacturerId === e.product.manufacturer.id
                    })
                    newData?.snapShot.push({
                        equipment_id: undefined,
                        equipment_name: e.product.name,
                        product_id: e.product.id,
                        part_number: e.product.part_no,
                        name: e.product.name,
                        quantity: e.count,
                        adjust_price: e.product.adjustedPrice,
                        price: e.product.price,
                        notes: e.product.note,
                        brand: e.product.vendor,
                        attachments: e.product.attachments,
                    })
                } else {
                    orderDetails.push({
                        id: e.product.savedId,
                        orderId: e.product.savedOrderId,
                        manufacturerId: e.product.manufacturer.id,
                        notes: '',
                        snapShot: [
                            {
                                equipment_id: undefined,
                                equipment_name: e.product.name,
                                part_id: e.product.id,
                                part_number: e.product.part_no,
                                name: e.product.name,
                                quantity: e.count,
                                adjust_price: e.product.adjustedPrice,
                                price: e.product.price,
                                notes: e.product.note,
                                brand: e.product.vendor,
                                attachments: e.product.attachments,
                            },
                        ],
                    })
                }
            })
            contextCustomParts.forEach((e) => {
                if (orderDetails.find((item) => item.manufacturerId === e.customPart.manufacturer.id)) {
                    let newData = orderDetails.find((item) => {
                        return item.manufacturerId === e.customPart.manufacturer.id
                    })
                    newData?.snapShot.push({
                        brand: e.customPart.manufacturer.name,
                        part_number: e.customPart.part_no,
                        name: e.customPart.name,
                        quantity: e.count,
                        adjust_price: e.customPart.price !== '-' ? e.customPart.price : 0,
                        price: e.customPart.price,
                        notes: e.customPart.note,
                        attachments: e.customPart.attachments,
                    })
                } else {
                    orderDetails.push({
                        id: e.customPart.savedId,
                        orderId: e.customPart.savedOrderId,
                        manufacturerId: e.customPart.manufacturer.id,
                        notes: '',
                        snapShot: [
                            {
                                equipment_name: e.customPart.manufacturer.name,
                                part_number: e.customPart.part_no,
                                name: e.customPart.name,
                                quantity: e.count,
                                adjust_price: e.customPart.price !== '-' ? e.customPart.price : 0,
                                price: e.customPart.price,
                                notes: e.customPart.note,
                                attachments: e.customPart.attachments,
                            },
                        ],
                    })
                }
            })
            contextCustomServices.forEach((e) => {
                if (orderDetails.find((item) => item.manufacturerId === e.customService.manufacturerId)) {
                    let newData = orderDetails.find((item) => {
                        return item.manufacturerId === e.customService.manufacturerId
                    })
                    newData?.snapShot.push({
                        brand: '',
                        part_number: '',
                        name: e.customService.name,
                        quantity: e.count,
                        adjust_price: e.customService.price !== '-' ? e.customService.price : 0,
                        price: e.customService.price,
                        notes: e.customService.note,
                        attachments: e.customService.attachments,
                    })
                } else {
                    orderDetails.push({
                        id: e.customService.savedId,
                        orderId: e.customService.savedOrderId,
                        manufacturerId: e.customService.manufacturerId,
                        notes: '',
                        snapShot: [
                            {
                                equipment_name: '',
                                part_number: '',
                                name: e.customService.name,
                                quantity: e.count,
                                adjust_price: e.customService.price !== '-' ? e.customService.price : 0,
                                price: e.customService.price,
                                notes: e.customService.note,
                                attachments: e.customService.attachments,
                            },
                        ],
                    })
                }
            })
            const newOrderDetails: {
                id?: string
                orderId?: string
                manufacturerId?: string
                notes: string
                snapShot: string
            }[] = []

            orderDetails.forEach((e) => {
                newOrderDetails.push({
                    id: type === CheckoutType.UpdateCart ? e.id : undefined,
                    orderId: e.orderId,
                    manufacturerId: e.manufacturerId,
                    notes: e.notes,
                    snapShot: JSON.stringify(e.snapShot),
                })
            })

            return newOrderDetails
        }

        const user = TokenService.getAuth()

        const setCustomerId = () => (user.company.type === CompanyType.Manufacturer ? cartInformation.purchaseForCustomerId : undefined)

        const newData: CreateOrderOrQuoteInput = {
            userId: userId,
            customerId: purchaseWithoutCustomer ? undefined : setCustomerId(),
            billingAddress: JSON.stringify(billingAddress),
            billingAddressNote: billingAddress.note,
            shippingAddress: JSON.stringify(shippingAddress),
            shippingAddressNote: shippingAddress.note,
            shippingCost: Number(shippingMethod.shippingCost),
            shipVia: shippingMethod.shipVIA,
            shippingType: shippingMethod.type,
            shippingNotes: shippingMethod.note,
            orderDetails: getSummaryData(),
            recipientEmails: recipientsEmails.emails || [],
            messageToRecipients: recipientsEmails.note,
            checkoutType: checkoutType,
            isSaved: type === CheckoutType.DraftCart || type === CheckoutType.UpdateCart,
            savedAs: savedCartInformation.name,
            isOnBehalf: user.company.type === CompanyType.Manufacturer,
            customerName: purchaseWithoutCustomer ? customerNameOfPurchaseWithoutCustomer : undefined,
        }
        return newData
    }

    const handlePurchaseWithoutCustomer = async (val: boolean) => {
        setPurchaseWithoutCustomer(val)
        if (val) {
            console.log('oldu')
            await setCartInformation({
                ...cartInformation,
                quickCartShow: true,
            })
        }
    }

    return {
        customerNameOfPurchaseWithoutCustomer,
        purchaseWithoutCustomer,
        handlePurchaseWithoutCustomer,
        setPurchaseWithoutCustomer,
        handleCartInformation,
        shippingAddress,
        handleShippingAddress,
        billingAddress,
        handleBillingAddress,
        isSameAddress,
        handleIsSameAddress,
        savedCartInformation,
        setSavedCartInfo,
        quickCartInformation,
        setQuickCartInfo,
        shippingMethod,
        handleShippingMethod,
        recipientsEmails,
        handleRecipientsEmails,
        clearAll,
        // getAddressCallBack,
        checkoutType,
        handleCheckoutType,
        fillOrder,
        cartInformation,
        contextProducts,
        contextCustomParts,
        contextEquipments,
        contextCustomServices,
        handlePurchaseForName,
        addPart,
        addParts,
        addAdditionalItem,
        addAdditionalServiceItem,
        removeAdditionalItem,
        removeAdditionalServiceItem,
        addProduct,
        removePart,
        removeProduct,
        setPartCount,
        setAdditionalItemCount,
        setProductCount,
        clearPart,
        clearProduct,
        toggleCartView,
        toggleQuickCartView,
        getCount,
        getTotalPrice,
        getGroupedItemsByManufacturer,
        setPartPrice,
        setProductPrice,
        setPartNote,
        setPartAttachment,
        setProductNote,
        setProductAttachment,
        setAdditionalItemPrice,
        setAdditionalItem,
        setAdditionalService,
        addCustomPart,
        addCustomService,
        removeCustomPart,
        removeCustomService,
        createCheckoutPayload,
        setCustomPartAttachment,
        setCustomServiceAttachment,
        setAdditionalServiceItemCount,
        setAdditionalServiceItemPrice,
        reset,
        quickCartReset,
        handleCustomerNameOfPurchaseWithoutCustomer,
        reOrderNotes,
    }
}

export const CheckoutContext = createContext({} as ReturnType<typeof useContext>)

const CheckoutContextProvider: FC<ICheckoutProps> = ({ children }) => <CheckoutContext.Provider value={useContext()}>{children}</CheckoutContext.Provider>

export default CheckoutContextProvider
