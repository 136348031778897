import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ContentBox from '../../../components/ContentBox'
import PageWrapper from '../../PageWrapper'
import CustomerService from '../../../services/UiService/Customer'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../components/Loading'
import './EquipmentList.scss'
import SearchInput from '../../../components/SearchInput'
import { arraySort } from '../../../utils/helpers'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import Icons, { IconType } from '../../../Icon'
import EasyIcon from '../../../components/EasyIcon'
import EquipmentListTable from './Table/EquipmentListTable'
import Button from '../../../components/Button'
import { CustomerManufacturerContext } from '../../../store/CustomerDashboardContext'
import NoDataInfo from '../../../components/NoDataInfo'
import EquipmentCart from '../Home/Components/Equipments/EquipmentCart'
import { EquipmentModel } from '../../../models/New/Equipment'

function EquipmentListForCustomer() {
    const { selectedManufacturerInfo: selectedManufacturer } = useContext(CustomerManufacturerContext)
    const [filteredData, setFilteredData] = useState([] as EquipmentModel[])
    const [customEquipments, setCustomEquipments] = useState([] as EquipmentModel[])
    const [loading, setLoading] = useState(false)
    const [selectedView, setSelectedView] = useState<boolean>(false)
    const [searchKey, setSearchKey] = useState<string>('')
    const navigate = useNavigate()

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Equipment',
        },
    ]

    useEffect(() => {
        const customerService = new CustomerService()
        async function getData() {
            setLoading(true)
            try {
                const equipment = await customerService.getEquipments({ page: 0, size: 9999, search: '' })
                setFilteredData(equipment.content)
                setCustomEquipments(arraySort(equipment.content, 'name'))
            } catch (e: any) {
                toast.error(e)
            }

            setLoading(false)
        }
        getData()
    }, [navigate, selectedManufacturer])

    const handleSearchedData = (data: EquipmentModel[]) => {
        setFilteredData(data)
    }

    return (
        <PageWrapper breadcrumb={breadcrumb}>
            {loading && <Loading />}
            <ContentBox
                title="Equipment"
                className="customer-equipment-list-page-container"
                headerIcon={<EasyIcon icon={IconType.Equipment} />}
                headerRight={
                    <div className="header-right">
                        <SearchInput data={customEquipments} handleSearch={handleSearchedData} searchKeys={['name', 'manufacturer.name', 'tags']} setDefaultSearch={searchKey} />
                        <Button className="toggle-button" onClick={() => setSelectedView(!selectedView)}>
                            {!selectedView ? <Icons type={IconType.Menus} /> : <Icons type={IconType.CardView} />}
                        </Button>
                    </div>
                }
            >
                {!loading && filteredData && filteredData.length > 0 ? (
                    selectedView ? (
                        <div className="cards-container">
                            {filteredData.map((item) => {
                                return <EquipmentCart equipmentInfo={item} />
                            })}
                        </div>
                    ) : (
                        <EquipmentListTable
                            navigate={'/equipment/'}
                            setSearch={(e) => {
                                if (searchKey === e) {
                                    setSearchKey('')
                                } else {
                                    setSearchKey(e)
                                }
                            }}
                            data={filteredData.map((p) => {
                                return {
                                    name: p.name,
                                    vendor: p.manufacturerName,
                                    partNo: p.partNumber,
                                    tags: p.tags,
                                    id: p.id,
                                }
                            })}
                        />
                    )
                ) : (
                    <NoDataInfo />
                )}
            </ContentBox>
        </PageWrapper>
    )
}

export default EquipmentListForCustomer
