import './WelcomeBack.scss'
import { standardDate } from '../../../../../utils/helpers'

interface Props {
    userName: string
}

function WelcomeBack(props: Props) {
    return (
        <div className="come-back-container">
            <h1>Welcome back, {props.userName}!</h1>
            <span>{standardDate(new Date())}</span>
        </div>
    )
}

export default WelcomeBack
