import Icons, { IconType } from '../../Icon'
import Zoom from 'react-medium-image-zoom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import './EquipmentImageBox.scss'
import { ImageModel } from '../../models/New/Image'
import { FileUploader } from 'react-drag-drop-files'
import React, { useEffect, useRef } from 'react'

interface Props {
    title: string
    images: ImageModel[]
    updateEquipmentImageHandler?: (imageId: string, isFeatured: boolean) => void
    deleteEquipmentImageHandler?: (imageId: string) => void
    createEquipmentImageHandler?: (file: FileList) => void
}

function EquipmentImageBox(props: Props) {
    const { title, images, updateEquipmentImageHandler, deleteEquipmentImageHandler, createEquipmentImageHandler } = props
    const zoomWrapper = document.getElementById('zoom-wrapper')
    let carouselRef = useRef<Carousel>(null)

    useEffect(() => {
        carouselRef?.current?.selectItem({ selectedItem: 0, swiping: true })
    }, [images])

    return (
        <div className="image-box">
            <div className="image-box-header">
                <span className="title">{title}</span>
                {createEquipmentImageHandler && (
                    <div key={'add-image'} className="add-photo">
                        <FileUploader
                            multiple={true}
                            className="file-input"
                            handleChange={(fileList: FileList) => createEquipmentImageHandler(fileList)}
                            name="file"
                            children={<span className="add-photo">+add photo</span>}
                            types={['png', 'jpg', 'jpeg']}
                        />
                    </div>
                )}
            </div>
            <div className="image-box-content">
                {images ? (
                    <Carousel showArrows={false} showStatus={false} ref={carouselRef} showThumbs={false}>
                        {images.map((image) => {
                            return (
                                <div>
                                    <div key={image.id} className="image-wrapper">
                                        <Zoom portalEl={zoomWrapper!}>
                                            <img className="image-item zoom-image" src={image.url} alt={title} />
                                        </Zoom>
                                        {deleteEquipmentImageHandler && <Icons className="icon image-remove-icon" type={IconType.Close} onClick={() => deleteEquipmentImageHandler(image.id)} />}
                                        {updateEquipmentImageHandler &&
                                            (image.isFeatured ? (
                                                <Icons className="icon image-star-icon" type={IconType.Star} onClick={() => updateEquipmentImageHandler(image.id, false)} />
                                            ) : (
                                                <Icons className="icon image-star-border-icon" type={IconType.StarBorder} onClick={() => updateEquipmentImageHandler(image.id, true)} />
                                            ))}
                                    </div>
                                </div>
                            )
                        })}
                    </Carousel>
                ) : (
                    <>
                        {createEquipmentImageHandler && (
                            <FileUploader
                                multiple={true}
                                className="file-input"
                                handleChange={(fileList: FileList) => createEquipmentImageHandler(fileList)}
                                name="file"
                                children={
                                    <div className="no-image">
                                        <Icons type={IconType.YellowAddCircle} />
                                    </div>
                                }
                                types={['png', 'jpg', 'jpeg']}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default EquipmentImageBox
