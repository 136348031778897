import { CustomerSummaryModel } from '../../models/Ui/Customer'
import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { CustomerDetailModel, CustomerListModel, UpdateCustomerInformationForManufacturerModel } from '../../models/New/Customer'
import { PaginationInputModel, PaginationResponseModel } from '../../models/BaseModel'
import { EquipmentModel } from '../../models/New/Equipment'

export default class CustomerService {
    async getCustomersForManufacturer(params: PaginationInputModel): Promise<PaginationResponseModel<CustomerListModel>> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetCustomersForManufacturer, { params })
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }

    async customerDetailForManufacturer(id: string): Promise<CustomerDetailModel> {
        try {
            const response = await MonitorApi.get(`${ServiceFunctionLookupTable.CustomerDetailForManufacturer}${id}`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Customer not found!')
        }
    }

    async customerDetailForCustomer(id: string): Promise<CustomerDetailModel> {
        try {
            const response = await MonitorApi.get(`${ServiceFunctionLookupTable.CustomerDetailForCustomer}${id}`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Customer not found!')
        }
    }
    async updateCustomerInformationForManufacturer(id: string, data: UpdateCustomerInformationForManufacturerModel): Promise<boolean> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.updateCustomerInformationForManufacturer + id, { ...data }, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async getByMail(email: string): Promise<CustomerSummaryModel | undefined> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.isMailExist + email, {})
            return response as any
        } catch (error: any) {
            return undefined
        }
    }

    async getEquipments(params: PaginationInputModel): Promise<PaginationResponseModel<EquipmentModel>> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.CustomForCustomer, { params: params })
            return response as any
        } catch (error: any) {
            console.error(error)
            return {} as any
        }
    }

    async getContacts() {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetContacts, {})
            return response as any
        } catch (error: any) {
            console.log(error)
            return {} as any
        }
    }

    // async acceptPendingInvitations(customerId: string): Promise<boolean> {
    //     try {
    //         const customer = await this.customerDbService.get(customerId);

    //         if (customer) {
    //             customer.equipments.forEach((equipment) => {
    //                 if (equipment.status === CustomerEquipmentStatus.Pending) {
    //                     equipment.status = CustomerEquipmentStatus.Accepted
    //                 } /* else not needed */
    //             })

    //             customer.products.forEach((product) => {
    //                 if (product.status === CustomerEquipmentStatus.Pending) {
    //                     product.status = CustomerEquipmentStatus.Accepted
    //                 } /* else not needed */
    //             })

    //             const response = await this.customerDbService.update(customerId, { ...customer })
    //             return response;
    //         }
    //         else {
    //             throw new Error('Customer not found');
    //         }
    //     } catch (e: any) {
    //         throw new Error(e);
    //     }
    // }

    // async updateCustomerEquipmentDetails(data: UpdateCustomEquipmentDetailsModel) {
    //     try {
    //         const customer = await this.customerDbService.get(data.customerId)

    //         if (!customer) throw new Error("Customer not found!");

    //         let equipment = customer.equipments.find((e) => e.ref.id === data.equipmentId)

    //         if (!equipment) throw new Error("Equipment not found in Customer!");

    //         equipment.location = data.location ? data.location : {} as CustomerEquipmentLocationModel
    //         equipment.serial_no = data.serialNo ? data.serialNo : ''

    //         await this.customerDbService.update(data.customerId, { ...customer })
    //     } catch (error: any) {
    //         console.error(error);
    //         throw new Error(error);
    //     }
    // }
}
