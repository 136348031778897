import { toast } from 'react-toastify'
import ViewAsCustomer from '../../../../../components/ViewAsCustomer'
import { EquipmentModel, NewUpdateEquipmentModel } from '../../../../../models/New/Equipment'
import EquipmentService from '../../../../../services/UiService/Equipment'
import NewNotepad from './../../../../../components/NewNotepad/index'
import NewEquipmentDetails from './NewEquipmentDetailsTab'
import './style.scss'

interface Props {
    isRootEquipment: boolean
    equipment: EquipmentModel
}

function DetailsTab(props: React.PropsWithChildren<Props>) {
    async function saveNoteHandler(data: NewUpdateEquipmentModel) {
        try {
            await EquipmentService.update(props.equipment.id, data)
        } catch (error: any) {
            toast.error(error)
        }
    }

    return (
        <div className="customer-content-container">
            <NewEquipmentDetails isRootEquipment={props.isRootEquipment} equipment={props.equipment} />
            <div className="notepad-container">
                <ViewAsCustomer>
                    <NewNotepad id={props.equipment.id as string} notes={props.equipment.notes as string} saveNote={(data: NewUpdateEquipmentModel) => saveNoteHandler(data)} />
                </ViewAsCustomer>
            </div>
        </div>
    )
}

export default DetailsTab
