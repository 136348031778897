import { useEffect, useState } from 'react'
import PopoverItem from '../../../../../../../components/PopoverItem'
import { CustomerEquipmentLocationModel } from '../../../../../../../models/Db/Customer'
import { EquipmentModel } from '../../../../../../../models/New/Equipment'
import { calculateAge, standardDate } from '../../../../../../../utils/helpers'
import './EquipmentInfoCart.scss'

interface Props {
    equipment: EquipmentModel
}

function EquipmentInfoCart(props: Props) {
    const warrantyDate = props.equipment.warrantyStarts ? standardDate(props.equipment.warrantyStarts) : '-'
    const warrantyEndDate = props.equipment.warrantyEnds ? standardDate(props.equipment.warrantyEnds) : '-'
    const [address, setAddress] = useState<CustomerEquipmentLocationModel | undefined>(props.equipment.installationAddress || undefined)

    useEffect(() => {
        setAddress(props.equipment.installationAddress)
    }, [props.equipment.installationAddress])

    const warrantyInfo = () => {
        switch (props.equipment.warrantyStatus) {
            case 'Expired':
                return (
                    <div className="warranty-info">
                        <div className="warranty expired">Warranty Expired</div>
                        <div className="date expired-date">
                            {warrantyDate}-{warrantyEndDate}
                        </div>
                    </div>
                )
            case 'Active':
                return (
                    <div className="warranty-info">
                        <div className="warranty active">Active Warranty</div>
                        <div className="date active-date">
                            {warrantyDate}-{warrantyEndDate}
                        </div>
                    </div>
                )
        }
    }
    const warrantyInfoClassName = () => {
        if (!props.equipment.warrantyStatus) {
            return 'no-info'
        } else {
            switch (props.equipment.warrantyStatus) {
                case 'Active':
                    return 'active'
                case 'Expired':
                    return 'expired'
            }
        }
    }

    return (
        <div className="custom-equipment-info-cart-container">
            <div className="left-container">
                {props.equipment.serialNo && (
                    <div className="serial-box">
                        <span className="title">Serial number</span>

                        <PopoverItem popoverContent={props.equipment.serialNo}>
                            <span className="value">{props.equipment.serialNo.slice(0, 15)}</span>
                        </PopoverItem>
                    </div>
                )}
                {props.equipment.installationDate && (
                    <div className="age-box">
                        <span className="title">Equipment Age</span>

                        <span className="value">{calculateAge(props.equipment.installationDate)}</span>
                    </div>
                )}
            </div>

            <div className="divider" />

            <div className="warranty-container">
                {props.equipment.warrantyStatus && (
                    <div className={`warranty-box ${warrantyInfoClassName()}`}>
                        <span className="title">Warranty Info</span>
                        <div className="content">{warrantyInfo()}</div>
                    </div>
                )}
                {props.equipment.installationDate && (
                    <div className="installation">
                        <span className="title">Installation Date</span>

                        <span className="value">{standardDate(props.equipment.installationDate)}</span>
                    </div>
                )}
            </div>

            {(address?.street1 || address?.street1 || address?.city || address?.state || address?.country || address?.postcode) && (
                <div className="address-box">
                    <span className="title">Installation Address</span>

                    <div className="content">
                        {address?.street1 && <span>{address?.street1}</span>}
                        {address?.street2 && <span>{address?.street2}</span>}
                        <div>
                            {address?.city && <span>{address?.city}, </span>}
                            {address?.state && <span>{address?.state}, </span>}
                            {address?.postcode && <span>{address?.postcode} </span>}
                        </div>
                        {address?.country && <span>{address?.country}</span>}
                    </div>
                </div>
            )}
        </div>
    )
}

export default EquipmentInfoCart
