import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { arraySort, isAbbreviation } from '../../../../../utils/helpers'
import Icons, { IconType } from '../../../../../Icon'
import './style.scss'
import { priceFormatter } from '../../../../../utils/helpers'
import { ReactNode, useEffect } from 'react'
import NoDataInfo from '../../../../../components/NoDataInfo'

interface Data {
    name: string
    category: string
    brand: string
    pkg: string
    partNo: string
    qty: number
    price: number
    button: ReactNode
    no: number
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    data: Data[]
}

const columns: HeadCell[] = [
    { label: 'No.', id: 'no' },
    { label: 'Part name', id: 'name' },
    { label: 'Category', id: 'category' },
    { label: 'Brand', id: 'brand' },
    { label: 'QTY', id: 'qty' },
    { label: 'Pkg', id: 'pkg' },
    { label: 'Part Number', id: 'partNo' },
    { label: 'Price', id: 'price' },
    { label: '', id: 'button' },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    return (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'button' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection(!tableProps.direction)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState('name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)

    useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container">
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />
                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                                        <TableCell style={{ width: '5%' }} align="left">
                                            {row.no}
                                        </TableCell>
                                        <TableCell style={{ width: '25%' }} className="font-bold" align="left">
                                            <span className="ellipsis-text">{row.name}</span>
                                            {isAbbreviation(row.name.length) && <div className="table-hover">{row.name}</div>}
                                        </TableCell>
                                        <TableCell style={{ width: '13%' }} align="left">
                                            {row.category}
                                        </TableCell>
                                        <TableCell style={{ width: '13%' }} align="left">
                                            {row.brand}
                                        </TableCell>
                                        <TableCell style={{ width: '10%' }} align="left">
                                            {row.qty}
                                        </TableCell>
                                        <TableCell style={{ width: '10%' }} align="left">
                                            {row.pkg}
                                        </TableCell>
                                        <TableCell align="left">{row.partNo}</TableCell>
                                        <TableCell align="left">{priceFormatter(row.price || '-')}</TableCell>
                                        <TableCell className="custom-button" align="center">
                                            {row.button}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
