/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Papa from 'papaparse'
import { PartsModel } from '../models/New/Equipment'
import { ProductListModel } from '../models/New/Product'
import { Location } from 'react-router-dom'
import { colorPallette, standartDateFormat } from './constants'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { OrderModel } from '../models/New/Order'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { FileModel } from '../services/NewServices/StorageService'

export function isAnyExistsPriceInParts(parts: PartsModel[] | ProductListModel[]): boolean {
    if (parts == null) return false

    for (let i = 0; i < parts.length; i++) {
        if (parts[i].price != null) return true
    }
    return false
}

export function isMobile() {
    return window.innerWidth < 768
}

export function isValidEmail(email: string): boolean {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

export function isValidPassword(password: string): boolean {
    let regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    return regex.test(password)
}

export function csvToJson(file: File, callback: (json: any) => void) {
    Papa.parse(file, {
        header: true,
        complete: function (results) {
            callback(results.data)
        },
    })
}

export function priceFormatter(price: number | '-') {
    if (price === '-') {
        return ''
    } else {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(price)
    }
}

// export function convertNumberFromCurrency(price: string) {
//     return parseFloat(price.replace('$', '').replaceAll(',', ''))
// }

export function buildShortName(name: string) {
    if (name.length >= 2) {
        if (name.split(' ').length >= 2) {
            return name.split(' ')[0][0].toUpperCase() + name.split(' ')[1][0].toUpperCase()
        } else {
            return name[0].toUpperCase() + name[1].toUpperCase()
        }
    } else {
        return '-'
    }
}

export function getRandomKey(length: number = 20): string {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

// export interface EquipmentTreeData {
//     id?: string
//     text: string
//     childNodes?: EquipmentTreeData[]
// }

export function dataUrltoFile(dataUrl: string, fileName: string) {
    let arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)![1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, { type: mime })
}

// export async function getFileFromUrl(url: string, name: string, defaultType = 'image/jpeg') {
//     const response = await fetch(url)
//     const data = await response.blob()
//     return new File([data], name, {
//         type: data.type || defaultType,
//     })
// }

// export function textCutter(text: string, length = 30) {
//     if (text.length > length) {
//         text = `${text.substring(0, length)}...`
//     }
//
//     return text
// }

export function isURL(url: string) {
    let pattern = new RegExp(
        '^' +
            // protocol identifier (optional)
            // short syntax // still required
            '(?:(?:(?:https?|ftp):)?\\/\\/)' +
            // user:pass BasicAuth (optional)
            '(?:\\S+(?::\\S*)?@)?' +
            '(?:' +
            // IP address exclusion
            // private & local networks
            '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
            '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
            '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
            // IP address dotted notation octets
            // excludes loopback network 0.0.0.0
            // excludes reserved space >= 224.0.0.0
            // excludes network & broadcast addresses
            // (first & last IP address of each class)
            '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
            '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
            '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
            '|' +
            // host & domain names, may end with dot
            // can be replaced by a shortest alternative
            // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
            '(?:' +
            '(?:' +
            '[a-z0-9\\u00a1-\\uffff]' +
            '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
            ')?' +
            '[a-z0-9\\u00a1-\\uffff]\\.' +
            ')+' +
            // TLD identifier name, may end with dot
            '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
            ')' +
            // port number (optional)
            '(?::\\d{2,5})?' +
            // resource path (optional)
            '(?:[/?#]\\S*)?' +
            '$',
        'i'
    )
    return pattern.test(url)
}

export enum UploadFileType {
    Image = 'image',
    Pdf = 'pdf',
    Video = 'video',
    Url = 'url',
}

export enum UploadFileReadableType {
    Image = 'Image',
    Pdf = 'PDF',
    Video = 'VIDEO',
    Url = 'URL',
}

// create a function that gets the file type from url
export function getFileTypeFromUrl(url: string) {
    const fileType = url?.split('.')?.pop()?.toLowerCase()

    if (fileType?.startsWith('jpg') || fileType?.startsWith('jpeg') || fileType?.startsWith('png') || fileType?.startsWith('gif')) {
        return UploadFileType.Image
    }
    if (fileType?.startsWith('pdf')) {
        return UploadFileType.Pdf
    }
    if (fileType?.startsWith('mp4') || fileType?.startsWith('mov')) {
        return UploadFileType.Video
    }
    if (isURL(url)) {
        return UploadFileType.Url
    }
}

export function getFileType(fileUrl: string, isReadable?: boolean) {
    const type = UploadFileType
    const readableType = UploadFileReadableType
    let urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    )
    if (urlPattern.test(fileUrl)) {
        let url = new URL(fileUrl)
        if (!url) {
            throw new Error('URL is not valid!')
        } else {
            // if (url?.href?.toLowerCase().startsWith('https://firebasestorage.googleapis.com/v0/b/' + (window.location.hostname === production_host_name ? projectId : projectIdDev) + '.appspot.com')) {
            if (url?.href?.toLowerCase().startsWith('https://firebasestorage.googleapis.com/v0/b/')) {
                const urlString = url?.pathname?.toLowerCase()
                if (urlString?.endsWith('png') || urlString?.endsWith('jpg') || urlString?.endsWith('jpeg')) {
                    return (isReadable ? readableType : type).Image as UploadFileType
                } else if (urlString.endsWith('.pdf')) {
                    return (isReadable ? readableType : type).Pdf as UploadFileType
                } else if (urlString.endsWith('.mp4')) {
                    return (isReadable ? readableType : type).Video as UploadFileType
                } else {
                    throw new Error('Unknown file type!')
                }
            } else {
                return (isReadable ? readableType : type).Url as UploadFileType
            }
        }
    }
}

export function isIconDefinition(icon: any) {
    return icon.icon !== undefined
}

export const getElementFontSizeAsPixel = (el: HTMLElement, removePx = true): number | string => {
    let fontSize = window.getComputedStyle(el, null).getPropertyValue('font-size')
    if (removePx) {
        return parseFloat(fontSize)
    }
    return fontSize
}

// if sorted field type is string date, ensure that date format must be standart date format. otherwise this function assume that is string
export function arraySort(array: Array<any>, field: string, orderType: 'ascending' | 'descending' = 'ascending'): Array<any> {
    const type = array.length > 0 && array[0][field] !== undefined ? typeof array[0][field] : undefined
    if (field === '') {
        let result: any[]
        result = array
        return orderType === 'ascending' ? result : result.reverse()
    } else {
        let result = []
        if (type) {
            if (type === 'string') {
                dayjs.extend(customParseFormat)
                if (dayjs(array[0][field], standartDateFormat, true).isValid()) {
                    result = array.sort((e1, e2) => {
                        const newLocalDate1: any = new Date(e1[field])
                        const newLocalDate2: any = new Date(e2[field])
                        return newLocalDate1 - newLocalDate2
                    })
                } else {
                    array = array.map((child) => ({ ...child, [field]: child[field] || '.' }))
                    result = array.sort((e1, e2) => {
                        if (e1[field] !== undefined && e2[field] !== undefined) {
                            if (String(e1[field]).toLowerCase() > String(e2[field]).toLowerCase()) {
                                return 1
                            } else if (String(e1[field]).toLowerCase() < String(e2[field]).toLowerCase()) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return 0
                        }
                    })
                    result = result.map((child) => ({ ...child, [field]: child[field] === '.' ? '' : child[field] }))
                }
            } else if (type === 'number') {
                result = array.sort((e1, e2) => {
                    if (e1[field] !== undefined && e2[field] !== undefined) {
                        if (e1[field] > e2[field]) {
                            return 1
                        } else if (e1[field] < e2[field]) {
                            return -1
                        } else {
                            return 0
                        }
                    } else {
                        return 0
                    }
                })
            } else if (type === 'boolean') {
                result = array.sort((e1, e2) => {
                    if (e1[field] !== undefined && e2[field] !== undefined) {
                        if (String(e1[field]).toLowerCase() > String(e2[field]).toLowerCase()) {
                            return 1
                        } else if (String(e1[field]).toLowerCase() < String(e2[field]).toLowerCase()) {
                            return -1
                        } else {
                            return 0
                        }
                    } else {
                        return 0
                    }
                })
            } else {
                result = array
            }
            return orderType === 'ascending' ? result : result.reverse()
        } else {
            return orderType === 'ascending' ? array : array.reverse()
        }
    }
}

// export function arrayEquals(a: Array<any>, b: Array<any>) {
//     return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
// }

export function objectDeepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj))
}

export function arrayDeepCopy(array: Array<any>) {
    return JSON.parse(JSON.stringify(array))
}

// export function generateCloneWithEndString(val: string): string {
//     const date = dayjs().format('YYMMDD-HH:mm:ss')
//     const random = getRandomKey(3)
//     return val + ' (' + date + '-' + random + ')'
// }

// export function clearUndefinedValues(data: any) {
//     Object.keys(data).forEach((key) => data[key] === undefined && delete data[key])
//     return data
// }

export function isAbbreviation(length: number, defaultLength: number = 19): boolean {
    return length > defaultLength
}

export function showActiveMenuItem(location: Location, firstPartOfPath: string): boolean {
    return location?.pathname?.substring(1)?.split('/')?.at(0)?.includes(firstPartOfPath) ?? false
}

export function uniqueObjectArray(args: any[]): any[] {
    const stringifiedArray = args.map((child) => JSON.stringify(child))
    const uniqueOnes = stringifiedArray.filter((value, index, self) => self.indexOf(value) === index)
    return uniqueOnes.map((child) => JSON.parse(child))
}

export function stringToLocalDate(dateString: string): Date {
    var pattern = /(\d{2})\.(\d{2})\.(\d{4})/
    var dt = new Date(dateString.replace(pattern, '$3-$2-$1'))
    return new Date(dt)
}

// export function fixDateString2(dateString: string): Date {
//     const dateParts = dateString.split('-')
//     return new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]))
// }

// export function fixDateString3(dateString: string): Date {
//     return new Date(dateString + ' EDT')
// }

export function calculateAge(date: string) {
    const today = dayjs(new Date())
    const initialDate = dayjs(date)
    const ageInMonths = today.diff(initialDate, 'month')
    const ageInYears = today.diff(initialDate, 'year', true).toFixed(1)
    return ageInMonths < 12 ? ageInMonths + (ageInMonths > 1 ? ' months' : ' month') : ageInYears + (JSON.parse(ageInYears) >= 2 ? ' years' : ' year')
}

export function standardDate(date: Date | string): string {
    // input: date or stringified date
    if (date === null || date === undefined || date === '') {
        return ''
    }
    let localizedDate = stringToLocalDate(date?.toString())
    dayjs.extend(localizedFormat)
    return dayjs(localizedDate).format(standartDateFormat)
}

export function arrayIterator<T>(value: Array<T>, len: number): Array<T> {
    if (len === 0) return []
    let a: Array<T> | Array<Array<T>> = [value]
    while (a.length * 2 <= len) a = a.concat(a)
    if (a.length < len) a = a.concat(a.slice(0, len - a.length))
    a = [].concat.apply([], a as any)
    return a as Array<T>
}

export const getRandomColorFromPalette = () => colorPallette[Math.floor(Math.random() * colorPallette.length)]

export const calculateTotalPrice = (order: OrderModel) => {
    let totalPrice = 0
    for (let index = 0; index < order.orderDetails.length; index++) {
        const equipment = order.orderDetails[index]
        for (let index = 0; index < JSON.parse(equipment.snapShot).length; index++) {
            const part = JSON.parse(equipment.snapShot)[index]
            totalPrice += Number(part.adjust_price || 0) * Number(part.quantity)
        }
    }
    return totalPrice
}

export const formatQuantity = (value: string) => {
    const number = Number(value)
    if (number > 0 && !isNaN(number)) {
        return parseInt(Number(value).toFixed())
    } else {
        return 1
    }
}

export const downloadFile = (file: FileModel, process?: (loading: boolean) => void) => {
    if (process) process(true)
    axios
        .get(file.url, {
            responseType: 'blob',
        })
        .then((res) => {
            fileDownload(res.data, file.name)
        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            if (process) process(false)
        })
}
export const differenceBetweenTwoArray = (array1: Array<any>, array2: Array<any>) => {
    const difference = array1.filter((x) => !array2.includes(x)).concat(array2.filter((x) => !array1.includes(x)))
    return difference
}
