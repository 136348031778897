import { useEffect, useState } from 'react'
import BasicDatePicker from '../../../../../components/DatePicker'
import Input from '../../../../../components/Input'
import Modal from '../../../../../components/Modal'
import { MaxCharacter } from '../../../../../models/enums'
import { EquipmentModel, NewUpdateEquipmentModel, UpdateCustomEquipmentModel } from '../../../../../models/New/Equipment'
import { EquipmentActionTypes } from '../../../../../models/Ui/Common'
import EquipmentService from '../../../../../services/UiService/Equipment'
import './style.scss'
import dayjs from 'dayjs'
import TextArea from '../../../../../components/TextArea'
import BasicDropdown from '../../../../../components/Dropdown/BasicDropdown'

interface Props {
    isHubspotSupport?: boolean
    equipment: EquipmentModel
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel) => void
    onError: (error: any) => void
}

function EditCustomEquipmentModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props
    const [name, setName] = useState<string>('')
    const [brand, setBrand] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [partNumber, setPartNumber] = useState<string>('')
    const [serialNumber, setSerialNumber] = useState<string>('')
    const [address1, setAddress1] = useState<string>('')
    const [address2, setAddress2] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [postalCode, setPostalCode] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [warrantyDuration, setWarrantyDuration] = useState<number>()
    const [warrantyDurationUnit, setWarrantyDurationUnit] = useState<string>('')
    const [warrantyStarts, setWarrantyStarts] = useState<Date | null>(null)
    const [installationDate, setInstallationDate] = useState<Date | null>(null)

    const warrantyDurationUnitList = [
        {
            id: 1,
            text: 'Week(s)',
        },
        {
            id: 2,
            text: 'Month(s)',
        },
        {
            id: 3,
            text: 'Year(s)',
        },
    ]
    const [selectedUnit, setSelectedUnit] = useState({ id: warrantyDurationUnitList.find((unit) => unit.text === props.equipment.warrantyDurationUnit), text: props.equipment.warrantyDurationUnit })
    useEffect(() => {
        setName(props.equipment?.name || '')
        setBrand(props.equipment?.brand || '')
        setDescription(props.equipment.description)
        setPartNumber(props.equipment?.partNumber || '')
        setSerialNumber(props.equipment?.serialNo || '')
        setAddress1(props.equipment.installationAddress?.street1 || '')
        setAddress2(props.equipment.installationAddress?.street2 || '')
        setCity(props.equipment.installationAddress?.city || '')
        setState(props.equipment.installationAddress?.state || '')
        setPostalCode(props.equipment.installationAddress?.postcode || '')
        setCountry(props.equipment.installationAddress?.country || '')
        setWarrantyDuration(props.equipment.warrantyDuration)
        setWarrantyDurationUnit(props.equipment.warrantyDurationUnit)
        if (props.equipment.installationDate) {
            setInstallationDate(new Date(props.equipment.installationDate))
        }
        if (props.equipment.warrantyStarts) {
            setWarrantyStarts(new Date(props.equipment.warrantyStarts))
        }
    }, [props, props.equipment.id])

    const isValidForm = () => {
        return name.length > 3 && partNumber.length > 0 && (!!selectedUnit.id || warrantyDuration! > 0 ? !!selectedUnit.id && warrantyDuration! > 0 : true)
    }

    const clearForm = () => {
        setName('')
        setBrand('')
        setDescription('')
        setPartNumber('')
        setSerialNumber('')
        setAddress1('')
        setAddress2('')
        setCity('')
        setState('')
        setPostalCode('')
        setCountry('')
        setWarrantyDuration(-1)
        setWarrantyDurationUnit('')
        setWarrantyStarts(null)
        setInstallationDate(null)
    }

    async function updateCustomEquipment() {
        try {
            onLoading()
            const newData: UpdateCustomEquipmentModel = {
                name: name,
                partNumber: partNumber,
                serialNo: serialNumber,
                warrantyDuration: warrantyDuration,
                warrantyDurationUnit: warrantyDurationUnit,
                warrantyStarts: warrantyStarts ? dayjs(warrantyStarts).format('MM/DD/YYYY') : '',
                installationAddress: {
                    street1: address1,
                    street2: address2,
                    city: city,
                    postcode: postalCode,
                    state: state,
                    country: country,
                },
                installationDate: installationDate ? dayjs(installationDate).format('MM/DD/YYYY') : '',
                description: description,
                brand: brand,
            }
            const response = await EquipmentService.updateCustomEquipment(props.equipment.id, newData)
            onSuccess(EquipmentActionTypes.UPDATE_EQUIPMENT, response)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
            clearForm()
        }
    }
    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }
    return (
        <>
            <Modal
                className="edit-customer-equipment-modal"
                visible={props?.visible}
                title="Edit equipment details"
                primaryButtonText="Save Changes"
                onClickPrimary={updateCustomEquipment}
                primaryDisabled={!isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
            >
                <div className="modal-line">
                    <Input value={partNumber} onChange={(e) => setPartNumber(e.target.value)} placeholder="PART NUMBER" required />
                </div>
                <div className="modal-line">
                    <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="EQUIPMENT NAME" required />
                </div>
                <div className="modal-line">
                    <Input value={brand} onChange={(e) => setBrand(e.target.value)} placeholder="BRAND" />
                </div>
                <div className="modal-line">
                    <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="DESCRIPTION" />
                </div>
                Customer's Equipment Information
                <div className="modal-line">
                    <Input value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} placeholder="SERIAL NUMBER" />
                </div>
                {!props.isHubspotSupport && (
                    <>
                        <div className="modal-line warranty-wrapper">
                            <>
                                <Input
                                    required={!!selectedUnit.id || warrantyDuration! > 0}
                                    value={warrantyDuration?.toString() || ''}
                                    type={'number'}
                                    onChange={(e) => setWarrantyDuration(Number.parseInt(e.target.value))}
                                    placeholder="WARRANTY DURATION"
                                />
                                <BasicDropdown
                                    isClearButton
                                    required={!!selectedUnit.id || warrantyDuration! > 0}
                                    label="UNIT"
                                    isClearLabel="Clear"
                                    menuItems={warrantyDurationUnitList}
                                    onChange={(data) => {
                                        setWarrantyDurationUnit(data.text)
                                        setSelectedUnit(data)
                                    }}
                                    handleClearInput={() => setSelectedUnit({ id: undefined, text: '' })}
                                    selectedItem={selectedUnit}
                                />
                            </>
                        </div>
                        <div className="modal-line warranty-wrapper">
                            <BasicDatePicker placeholder="START DATE" startDate={warrantyStarts} onDateChange={(date) => setWarrantyStarts(date)} />
                        </div>
                    </>
                )}
                Installation Address
                <div className="modal-line">
                    <Input value={address1} onChange={(e) => setAddress1(e.target.value)} placeholder="STREET ADDRESS 1" max={MaxCharacter.address} />
                </div>
                <div className="modal-line">
                    <Input value={address2} onChange={(e) => setAddress2(e.target.value)} placeholder="STREET ADDRESS 2" max={MaxCharacter.address} />
                </div>
                <div className="modal-line">
                    <Input max={MaxCharacter.subAddress} value={city} onChange={(e) => setCity(e.target.value)} placeholder="CITY" />
                    <Input max={MaxCharacter.subAddress} value={state} onChange={(e) => setState(e.target.value)} placeholder="STATE" />
                </div>
                <div className="modal-line">
                    <Input max={MaxCharacter.zipCode} value={postalCode} onChange={(e) => setPostalCode(e.target.value)} placeholder="POSTAL CODE" />
                    <Input max={MaxCharacter.subAddress} value={country} onChange={(e) => setCountry(e.target.value)} placeholder="COUNTRY" />
                </div>
                Equipment Life
                <div className="modal-line equipment-life">
                    <BasicDatePicker maxDate={new Date()} placeholder="INSTALLATION DATE" startDate={installationDate} onDateChange={(date) => setInstallationDate(date)} />
                </div>
            </Modal>
        </>
    )
}

export default EditCustomEquipmentModal
