import { useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import ContentBox from '../../../components/ContentBox'
import EasyIcon from '../../../components/EasyIcon'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import { IconType } from '../../../Icon'
import { arraySort } from '../../../utils/helpers'
import './OrderList.scss'
import { OrderModel } from '../../../models/New/Order'
import OrderService from './../../../services/UiService/Order'
import SearchInput from '../../../components/SearchInput'
import { CustomerManufacturerContext } from '../../../store/CustomerDashboardContext'
import Tab from '../../../components/Tab'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import EmptyData from './Components/EmptyData'
import PurchaseRequests from './Tabs/PurchaseRequests/PurchaseRequests'
import QuotesTab from './Tabs/Quotes/Quotes'
import { useSearchParams } from 'react-router-dom'

const tabItems: TabItem[] = [
    {
        id: Tabs.Quotes,
        name: 'Quotes',
    },
    {
        id: Tabs.PurchaseRequests,
        name: 'Purchase Requests',
    },
]

function OrderList() {
    const { selectedManufacturerInfo: selectedManufacturer } = useContext(CustomerManufacturerContext)
    let [searchParams] = useSearchParams()
    const activeTabId = parseInt(searchParams.get('activeTab') ? searchParams.get('activeTab')!.toString() : '0')
    const [orders, setOrders] = useState<OrderModel[]>([])
    const [filteredData, setFilteredData] = useState<OrderModel[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [activeTab, setActiveTab] = useState<number>(activeTabId)

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Orders',
        },
    ]

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])

    const getTransactionsDataCallback = useCallback(async () => {
        try {
            setLoading(true)
            switch (activeTab) {
                case Tabs.PurchaseRequests:
                    const orders = await OrderService.getPurchaseRequests()
                    setOrders(arraySort(orders, 'creationDate', 'descending'))

                    break
                case Tabs.Quotes:
                    const quotes = await OrderService.getCustomerQuotes()
                    setOrders(arraySort(quotes, 'creationDate', 'descending'))

                    break
                default:
                    break
            }
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }, [activeTab])

    useEffect(() => {
        getTransactionsDataCallback()
    }, [getTransactionsDataCallback, selectedManufacturer])

    const handleSearchedData = (data: OrderModel[]) => {
        setFilteredData(data)
    }

    return (
        <PageWrapper className="customer-transaction-page" breadcrumb={breadcrumb}>
            <ContentBox title="Orders" headerIcon={<EasyIcon icon={IconType.OrderBag} />}>
                {loading && <Loading />}
                {!loading && (
                    <>
                        <div className="tab-container">
                            <Tab
                                activeTab={activeTab}
                                tabs={tabItems}
                                onTabClick={(id: number) => {
                                    setActiveTab(id)
                                }}
                            />

                            <div className="right-buttons">
                                <SearchInput data={orders} handleSearch={handleSearchedData} searchKeys={['customer', 'transactionNumber', 'creationDate']} />
                            </div>
                        </div>
                        {!orders.length ? (
                            <EmptyData />
                        ) : (
                            <>
                                {activeTab === Tabs.Quotes && <QuotesTab visible={activeTab === Tabs.Quotes} orderData={filteredData} />}
                                {activeTab === Tabs.PurchaseRequests && (
                                    <PurchaseRequests onLoading={onLoadingCallback} onCompleted={onCompletedCallback} visible={activeTab === Tabs.PurchaseRequests} orderData={filteredData} />
                                )}
                            </>
                        )}{' '}
                    </>
                )}
            </ContentBox>
        </PageWrapper>
    )
}

export default OrderList
